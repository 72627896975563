<template>
    <cb-card :rounded="true" :big-rounded="true" class="amount-container">
        <div class="section-title padding-bottom-16">Enter an Amount</div>
        <cb-row v-if="IsRecurringDonationsFeatureEnabled">
            <cb-col class="buttons-container">
                <cb-button
                    label="One-Time Donation"
                    color="accent"
                    :inverted="IsRecurringDonation"
                    @click="IsRecurringDonation = false"
                ></cb-button>
                <cb-button
                    label="Monthly Donation"
                    color="accent"
                    :inverted="!IsRecurringDonation"
                    @click="IsRecurringDonation = true"
                ></cb-button>
            </cb-col>
        </cb-row>
        <cb-row class="donation-amount-container">
            <cb-col desktop="4" tablet="4" phone="6">
                <cb-money-input
                    v-model="DonationAmount"
                    label="Amount"
                    name="amount"
                    :validate="vuelidate.DonationAmount"
                    dense
                    money-whole
                    big
                />
            </cb-col>
        </cb-row>
        <cb-row class="donation-note-container">
            <cb-col>
                <cb-textarea
                    v-model="DonationNote"
                    label="Donation note (optional)"
                    name="note"
                    :validate="vuelidate.DonationNote"
                    :error="vuelidate.DonationNote.$error"
                    :errorMessage="vuelidate.DonationNote.$errors[0]?.$message"
                    dense
                    textareaAutogrow
                />
            </cb-col>
        </cb-row>
        <cb-row v-if="IsRecurringDonationsFeatureEnabled && IsRecurringDonation" class="donation-recurrence-day">
            <div style="width: 100%" class="separator" />
            <cb-col>
                <span class="recurring-text">
                    <label>This donation will repeat on the &nbsp;</label>
                    <cb-select
                        v-model="DayOfRecurrenceValue"
                        :options="DaysOfTheMonthMenuItems"
                        optionLabel="Name"
                        :error="vuelidate.DayOfRecurrence.$error"
                        errorMessage="Value is required"
                        class="validation-adjust"
                        @change="dayOfRecurrenceUpdated"
                    />
                    <label>&nbsp; of the month.</label>
                </span>
            </cb-col>
        </cb-row>
    </cb-card>
</template>

<script setup lang="ts">
import DayOfTheMonthMenuItem from "@/models/DayOfTheMonthMenuItem";
import { useDonationStore } from "@/stores/DonationStore";
import { useSiteSettings } from "@/stores/SiteSettingsStore";
import { GetOrdinalForDayOfTheMonth } from "@/utils/StringFunctions";
import useVuelidate from "@vuelidate/core";
import { required, minValue, helpers, maxLength, requiredIf } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

const donationStore = useDonationStore();
const { DonationAmount, DonationNote, IsRecurringDonation, DayOfRecurrence } = storeToRefs(donationStore);

const siteSettingsStore = useSiteSettings();
const { SiteSettings, IsRecurringDonationsFeatureEnabled } = storeToRefs(siteSettingsStore);

const validations = computed(() => ({
    DonationAmount: { required, minValue: minValue(SiteSettings.value.MinimumAmount) },
    DonationNote: {
        maxLength: helpers.withMessage(
            ({ $model }) => `This field has a character limit of 4000. You entered ${$model.length}.`,
            maxLength(4000),
        ),
    },
    DayOfRecurrence: {
        required: requiredIf(() => {
            return IsRecurringDonation.value;
        }),
    },
}));

const vuelidate = useVuelidate(validations, { DonationAmount, DonationNote, DayOfRecurrence }) as any;

const currentDate = new Date();
const DaysOfTheMonth: number[] = Array.from(
    { length: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate() },
    (_, i) => i + 1,
);

const DaysOfTheMonthMenuItems: DayOfTheMonthMenuItem[] = DaysOfTheMonth.map((dayOfTheMonth: number) => {
    return new DayOfTheMonthMenuItem(`${dayOfTheMonth}${GetOrdinalForDayOfTheMonth(dayOfTheMonth)}`, dayOfTheMonth);
});

const dayOfTheMonth: DayOfTheMonthMenuItem = DaysOfTheMonthMenuItems.find((day: DayOfTheMonthMenuItem) => {
    return day.Value === DayOfRecurrence.value;
});

const DayOfRecurrenceValue = ref<DayOfTheMonthMenuItem>(dayOfTheMonth);

function dayOfRecurrenceUpdated(newValue: DayOfTheMonthMenuItem): void {
    if (newValue) {
        DayOfRecurrence.value = newValue.Value;
    } else {
        DayOfRecurrence.value = null;
    }
}
</script>

<style scoped>
.amount-container {
    padding: 24px 80px 4px 80px;
}
.donation-amount-container {
    justify-content: center;
    margin-bottom: 20px;
}
.buttons-container {
    display: flex;
    justify-content: center;
    padding: 0px 0px 24px 0px;
}
.buttons-container .cb-wrapper + .cb-wrapper {
    padding-left: 16px;
}
.donation-wording {
    font-weight: 400;
    font-size: 16px;
}
.recurring-text {
    display: flex;
    align-items: center;
}
@media (max-width: 515px) {
    .amount-container {
        padding: 24px 30px;
    }
}
@media (min-width: 660px) {
    .validation-adjust :deep(.q-field__bottom) {
        margin-left: -20px;
        white-space: nowrap;
    }
}
</style>
