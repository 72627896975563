<template>
    <div>
        <cb-spinner v-if="Loading"></cb-spinner>
        <div v-if="!Loading">
            <div v-html="ConfirmationWording"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import Axios, { AxiosResponse } from "axios";
import { ref } from "vue";
import { useSiteSettings } from "@/stores/SiteSettingsStore";

const ConfirmationWording = ref<string>("");
const Loading = ref<boolean>(true);

const siteSettingsStore = useSiteSettings();
ConfirmationWording.value = siteSettingsStore.Confirmation.ConfirmationWording;

Axios.all([GetSiteInfo()])
    .then(
        Axios.spread((siteInfo: AxiosResponse<Gateway.OnlineGivingSiteDTO>) => {
            ConfirmationWording.value = siteInfo.data.Confirmation.ConfirmationWording;
            Loading.value = false;
            window.scrollTo(0, 0);
        }),
    )
    .catch(() => {
        Loading.value = false;
    });

function GetSiteInfo() {
    return Gateway.ApiGateway.GetSiteInfo();
}
</script>
