import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]

import { GetTextColourFromBackground } from "./utils/StringFunctions";
import { onBeforeMount } from "vue";
import router from "@/router";

import { applyOptions } from "@campbrain/cortex";
import { useSiteSettings } from "./stores/SiteSettingsStore";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const siteSettingsStore = useSiteSettings();
const { VisualSettings, SiteSettings, ButtonTextColour, IsValidSite, IsEnabled, IsLoading } = storeToRefs(siteSettingsStore);

async function GetVisualSettings() {
    IsLoading.value = true;
    await siteSettingsStore.getSiteInfo();

    if (!IsEnabled.value) {
        router.push({
            name: "SiteNotFound",
        });
        return;
    }

    if (!IsValidSite.value) {
        router.push({
            name: "SiteNotEnabled",
        });
        return;
    }

    ButtonTextColour.value = GetTextColourFromBackground(VisualSettings.value.DarkColor);

    document.title = VisualSettings.value.SiteName;

    if (SiteSettings.value.FacebookPixelEnabled) {
        (<any>window).fbq("init", SiteSettings.value.FacebookPixelId);
        (<any>window).fbq("track", "PageView");
    }

    applyOptions({
        colours: {
            primary: VisualSettings.value.BaseColor,
            accent: VisualSettings.value.DarkColor,
        },
    });

    router.push({
        name: "Form",
    });
}
onBeforeMount(async () => {
    await GetVisualSettings();
    IsLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_cb_header = _resolveComponent("cb-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_cb_page_layout = _resolveComponent("cb-page-layout")!

  return (_openBlock(), _createBlock(_component_cb_page_layout, {
    footerColor: _unref(VisualSettings)?.FooterColor,
    backgroundImage: _unref(VisualSettings)?.BackgroundImageURL,
    backgroundType: _unref(VisualSettings)?.BackgroundType,
    backgroundColor: _unref(VisualSettings)?.BackgroundColor
  }, {
    body: _withCtx(() => [
      (_unref(VisualSettings)?.HeaderImageUrl)
        ? (_openBlock(), _createBlock(_component_cb_header, {
            key: 0,
            headerImageUrl: _unref(VisualSettings)?.HeaderImageUrl,
            headerImageAlignment: _unref(VisualSettings)?.HeaderImageAlignment
          }, null, 8, ["headerImageUrl", "headerImageAlignment"]))
        : _createCommentVNode("", true),
      (!_unref(IsLoading))
        ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _unref(VisualSettings)?.Footer
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["footerColor", "backgroundImage", "backgroundType", "backgroundColor"]))
}
}

})