import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["innerHTML"]

import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import Axios, { AxiosResponse } from "axios";
import { ref } from "vue";
import { useSiteSettings } from "@/stores/SiteSettingsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmationPage',
  setup(__props) {

const ConfirmationWording = ref<string>("");
const Loading = ref<boolean>(true);

const siteSettingsStore = useSiteSettings();
ConfirmationWording.value = siteSettingsStore.Confirmation.ConfirmationWording;

Axios.all([GetSiteInfo()])
    .then(
        Axios.spread((siteInfo: AxiosResponse<Gateway.OnlineGivingSiteDTO>) => {
            ConfirmationWording.value = siteInfo.data.Confirmation.ConfirmationWording;
            Loading.value = false;
            window.scrollTo(0, 0);
        }),
    )
    .catch(() => {
        Loading.value = false;
    });

function GetSiteInfo() {
    return Gateway.ApiGateway.GetSiteInfo();
}

return (_ctx: any,_cache: any) => {
  const _component_cb_spinner = _resolveComponent("cb-spinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (Loading.value)
      ? (_openBlock(), _createBlock(_component_cb_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    (!Loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", { innerHTML: ConfirmationWording.value }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})