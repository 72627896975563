import { defineStore } from "pinia";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { AxiosResponse } from "axios";

export const useSiteSettings = defineStore("siteSettingsStore", {
    state: () => ({
        VisualSettings: {} as Gateway.GivingSiteLookAndFeelDTO,
        SiteSettings: {} as Gateway.GivingSiteSettingsDTO,
        Confirmation: {} as Gateway.GivingSiteConfirmationDTO,
        GoogleTagSettings: {} as Gateway.GoogleTagSettingsDTO,

        ButtonTextColour: "#fff",

        Description: "",
        CaptchaSiteKey: "",
        IsValidSite: false,

        FooterHeight: null as number,
        Version: "",

        IsEnabled: false,

        IsLoading: false,

        IsRecurringDonationsFeatureEnabled: false,

        CountryCodes: [] as string[],
    }),
    getters: {},
    actions: {
        async getSiteInfo() {
            const givingSite: AxiosResponse<Gateway.OnlineGivingSiteDTO> = await Gateway.ApiGateway.GetSiteInfo();

            if (givingSite.data.IsValidSite) {
                this.VisualSettings = givingSite.data.LookAndFeel;
                this.Description = this.VisualSettings.Description;
                this.SiteSettings = givingSite.data.SiteSettings;
                this.Confirmation = givingSite.data.Confirmation;
                this.Version = givingSite.data.Version;
                this.CaptchaSiteKey = givingSite.data.CaptchaSiteKey;
                this.GoogleTagSettings = givingSite.data.GoogleTagSettings;

                this.IsEnabled = givingSite.data.IsEnabled;

                //one is the feature flag(IsRecurringDonationsFeatureEnabled)
                // and one is the site setting (RecurringDonationsEnabled)
                this.IsRecurringDonationsFeatureEnabled =
                    givingSite.data.SiteSettings.RecurringDonationsEnabled &&
                    givingSite.data.SiteSettings.IsRecurringDonationsFeatureEnabled;
            }
            this.IsValidSite = givingSite.data.IsValidSite;
            this.CountryCodes = givingSite.data.CountryCodes;
        },
    },
});
