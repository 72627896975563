<template>
    <cb-modal v-model="ShowConfirmation" persistent customWidth="300">
        <cb-app class="modal-container">
            <cb-modal-page v-if="!IsSubmittingPayment && !PaymentDeclined">
                <template #header>
                    <div class="title">Review Your<span v-if="IsRecurringDonation"> Monthly</span> Donation</div>
                </template>
                <template #body>
                    <div class="review-table">
                        <cb-row class="table-row">
                            <cb-col desktop="3" tablet="3" phone="4">
                                <span class="description">Donation</span>
                            </cb-col>
                            <cb-col desktop="9" tablet="9" phone="8">
                                <span v-text="IsRecurringDonation ? RecurringDonationAmount : DonationAmountText" />
                            </cb-col>
                        </cb-row>
                        <cb-row class="table-row">
                            <cb-col desktop="3" tablet="3" phone="4">
                                <span class="description">Giving to</span>
                            </cb-col>
                            <cb-col desktop="9" tablet="9" phone="8">
                                <span v-text="SelectedGivingOption.Name" />
                            </cb-col>
                        </cb-row>
                        <cb-row v-if="SelectedDonationAttributionOption !== Gateway.DonationDedicationType.NoDedication" class="table-row">
                            <cb-col desktop="3" tablet="3" phone="4">
                                <span class="description">Dedication</span>
                            </cb-col>
                            <cb-col desktop="9" tablet="9" phone="8">
                                <span v-text="`${DedicationString}: ${DonationAttributionName}`" />
                            </cb-col>
                        </cb-row>
                        <cb-row v-if="DonationNote" class="table-row">
                            <cb-col desktop="3" tablet="3" phone="4">
                                <span class="description">Donation Note</span>
                            </cb-col>
                            <cb-col desktop="9" tablet="9" phone="8">
                                <span v-text="DonationNote" />
                            </cb-col>
                        </cb-row>
                    </div>

                    <cb-row v-if="IsRecurringDonation && DayOfRecurrence > 28">
                        <span class="last-day-text">
                            *Should an upcoming month not include this date, the donation will process on the last day of that month (i.e.
                            29th, 30th, 31st, etc.)
                        </span>
                    </cb-row>
                    <cb-row class="table-row" style="margin-top: 16px">
                        <cb-col desktop="3" tablet="3" phone="4">
                            <span class="description">Email</span>
                        </cb-col>
                        <cb-col desktop="9" tablet="9" phone="8">
                            <span v-text="Email" />
                        </cb-col>
                    </cb-row>

                    <div class="separator" :style="IsRecurringDonation ? `` : `margin-bottom: 0px`" />

                    <cb-row v-if="IsRecurringDonation">
                        <cb-col>
                            <cb-alert color="accent" class="banner-style">
                                Your first <b><span v-text="DonationAmountText"></span></b> donation will be processed
                                <b>
                                    <span
                                        v-text="
                                            CalculatedRecurrenceDate.toLocaleDateString('en-us', {
                                                month: 'short',
                                                day: 'numeric',
                                            })
                                        "
                                    />
                                    <span v-if="IsDayOfRecurrenceToday"> (today)</span> </b
                                >.
                            </cb-alert>
                        </cb-col>
                    </cb-row>
                </template>
                <template #footer>
                    <div class="buttons-container">
                        <cb-button class="col" color="accent" label="Confirm" :full-width="true" @click="submit" />
                        <cb-button label="Back" class="col" :full-width="true" @click="$emit('closeDialog')" />
                    </div>
                </template>
            </cb-modal-page>
            <cb-modal-page v-if="IsSubmittingPayment" style="text-align: center">
                <template #header>
                    <cb-spinner />
                </template>
                <template #body>
                    <p>Submitting...</p>
                </template>
                <template #footer>
                    <p>(This may take a moment)</p>
                </template>
            </cb-modal-page>
            <cb-modal-page v-if="!IsSubmittingPayment && PaymentDeclined && !IsRecurringDonation">
                <template #header>
                    <cb-icon name="fas fa-exclamation-triangle" color="red" /> We were unable to process your donation
                </template>
                <template #body>
                    <p style="text-align: center">Please check the credit card information and try again</p>
                </template>
                <template #footer>
                    <cb-button label="Close" @click="$emit('closeDialog', PaymentDeclined)" />
                </template>
            </cb-modal-page>
            <cb-modal-page v-if="!IsSubmittingPayment && PaymentDeclined && IsRecurringDonation">
                <template #header>
                    <cb-icon name="fas fa-exclamation-triangle" color="red" /> We were unable to process your donation
                </template>
                <template #body>
                    <p style="text-align: center">Your credit card will not be charged monthly going forward.</p>
                    <p style="text-align: center">Please check the credit card information and try again</p>
                </template>
                <template #footer>
                    <cb-button label="Close" @click="$emit('closeDialog', PaymentDeclined)" />
                </template>
            </cb-modal-page>
        </cb-app>
    </cb-modal>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import Events from "../models/Events";
import { useDonationStore } from "@/stores/DonationStore";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { computed } from "vue";

const donationStore = useDonationStore();
const {
    ShowConfirmation,
    IsSubmittingPayment,
    PaymentDeclined,
    DonationAmount,
    Email,
    IsRecurringDonation,
    SelectedGivingOption,
    DedicationString,
    DonationAttributionName,
    DonationNote,
    CalculatedRecurrenceDate,
    IsDayOfRecurrenceToday,
    DayOfRecurrence,
    DayOfRecurrenceOrdinal,
    SelectedDonationAttributionOption,
} = storeToRefs(donationStore);

const emits = defineEmits([Events.DonationSubmitted, "closeDialog"]);

const RecurringDonationAmount = computed<string>(() => {
    if (!IsRecurringDonation.value) {
        return "";
    }

    return `$${DonationAmount.value}/month (on the ${DayOfRecurrence.value}${DayOfRecurrenceOrdinal.value}${
        DayOfRecurrence.value > 28 ? "*" : ""
    })`;
});

const DonationAmountText = computed<string>(() => {
    return `$${DonationAmount.value}`;
});

function submit(): void {
    emits(Events.DonationSubmitted, null);
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
}
.description {
    font-weight: 500;
    font-size: 13px;
}
.modal-container {
    max-width: 440px;
}
.table-row {
    margin-top: -1px;
    font-weight: 400;
    font-size: 13px;
    border: 1px solid #e4e5e9;
}
.table-row > * {
    display: flex;
    align-items: center;
    padding: 8px;
}
.table-row .cb-col + .cb-col {
    border-left: 1px solid #e4e5e9;
}
.last-day-text {
    font-weight: 400;
    font-size: 10px;
    font-style: italic;
    text-align: center;
    padding-top: 8px;
}
:deep(.q-banner) {
    min-height: unset;
}
:deep(.q-card__actions) {
    padding: 0px 16px 16px 16px;
}
.buttons-container {
    display: flex;
    width: 100%;
}
.buttons-container .cb-wrapper + .cb-wrapper {
    padding-left: 8px;
}
.banner-style :deep(.q-banner) {
    color: unset !important;
    text-align: center;
    background-color: #3333331a !important;
}
@media (max-width: 1024px) {
    .modal-container {
        min-width: 340px;
    }
}
@media (min-width: 1024px) {
    .modal-container {
        min-width: 440px;
    }
}
:deep(.q-card__actions) {
    justify-content: center;
}
</style>
