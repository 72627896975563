import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DonationForm from "@/views/DonationForm.vue";
import ConfirmationPage from "@/views/ConfirmationPage.vue";
import SiteNotEnabled from "@/views/SiteNotEnabled.vue";
import SiteNotFound from "@/views/SiteNotFound.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Form",
        component: DonationForm,
    },
    {
        path: "/",
        name: "Confirmation",
        component: ConfirmationPage,
    },
    {
        path: "/SiteNotEnabled/",
        name: "SiteNotEnabled",
        component: SiteNotEnabled,
    },
    {
        path: "/SiteNotFound/",
        name: "SiteNotFound",
        component: SiteNotFound,
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/",
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
