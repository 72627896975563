<template>
    <div>
        <div class="text-h4 text-center">Site Not Found</div>
        <div class="text-body1 text-center">The site you are trying to access does not exist.</div>
    </div>
</template>

<script lang="ts">
export default {};
</script>
