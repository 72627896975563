<template>
    <cb-card :rounded="true" :big-rounded="true" class="direct-donation-container">
        <div class="section-title">Direct Your Donation</div>
        <cb-row class="direct-container">
            <cb-col>
                <cb-select
                    v-if="GivingOptions.length > 1"
                    v-model="SelectedGivingOptionId"
                    name=""
                    label="Please select"
                    :options="GivingOptions"
                    optionValue="Id"
                    optionLabel="Name"
                    :error="vuelidate.SelectedGivingOptionId.$error"
                    errorMessage="Value is required"
                    emit-value
                    map-options
                />
            </cb-col>
        </cb-row>
        <div
            v-if="SelectedGivingOption && SelectedGivingOption.Description"
            class="donation-image-text"
            v-html="SelectedGivingOption.Description"
        />
        <div v-if="DisplayTributeOptions" label="Dedication Information">
            <cb-row class="dedication-container" gutter="sm">
                <cb-col desktop="4" tablet="6">
                    <cb-select
                        v-if="DisplayTributeOptions"
                        v-model="SelectedDonationAttributionOption"
                        :options="DonationAttributionOptions"
                        optionLabel="Name"
                        optionValue="Value"
                        label="Dedication"
                        emit-value
                        map-options
                    />
                </cb-col>
                <cb-col desktop="6" tablet="6" class="mobile-gutter">
                    <cb-input
                        v-if="DisplayTributeOptions && (DisplayInMemoryOfTribute || DisplayInHonorOfTribute)"
                        v-model="DonationAttributionName"
                        class="input-adjustment"
                        label="Enter Name"
                        :error="vuelidate.DonationAttributionName.$error"
                        :errorMessage="vuelidate.DonationAttributionName.$errors[0]?.$message"
                    />
                </cb-col>
            </cb-row>
        </div>
    </cb-card>
</template>

<script setup lang="ts">
import { GivingOption } from "@/models/GivingOption";
import { useDonationStore } from "@/stores/DonationStore";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const donationsStore = useDonationStore();
const { SelectedGivingOptionId, SelectedDonationAttributionOption, DonationAttributionName, GivingOptions, DonationAttributionOptions } =
    storeToRefs(donationsStore);

SelectedDonationAttributionOption.value = DonationAttributionOptions.value[0].Value;

const SelectedGivingOption = computed<GivingOption>(() => {
    if (GivingOptions.value.length > 1) {
        return GivingOptions.value.filter((opt) => opt.Id === SelectedGivingOptionId.value)[0];
    } else {
        return GivingOptions.value[0];
    }
});

const DisplayTributeOptions = computed<boolean>(() => {
    const result =
        SelectedGivingOption.value && SelectedGivingOption.value.DonationAttribution == Gateway.DonationDedicationType.NoDedication;
    return result;
});

const DisplayInMemoryOfTribute = computed<boolean>(() => {
    return SelectedGivingOption.value && SelectedDonationAttributionOption.value == Gateway.DonationDedicationType.InMemoryOf;
});

const DisplayInHonorOfTribute = computed<boolean>(() => {
    return SelectedGivingOption.value && SelectedDonationAttributionOption.value == Gateway.DonationDedicationType.InHonourOf;
});

const validations = computed(() => ({
    SelectedGivingOptionId: {
        required: requiredIf(() => {
            return GivingOptions.value.length > 1;
        }),
    },
    DonationAttributionName: {
        required: requiredIf(() => {
            return DisplayTributeOptions.value && (DisplayInMemoryOfTribute.value || DisplayInHonorOfTribute.value);
        }),
    },
}));

const vuelidate = useVuelidate(validations, {
    DonationAttributionName,
    SelectedGivingOptionId,
});
</script>

<style scoped>
.direct-donation-container {
    padding: 24px 24px 0px 24px;
}
.direct-container {
    padding: 16px 0px 24px 0px;
}
.dedication-container {
    padding-bottom: 18px;
}
.dedication-memory {
    padding-top: 12px;
}
.input-adjustment :deep(label) {
    padding-bottom: 0px;
}
.donation-image-text {
    margin: 0px 24px 16px 24px;
    text-align: center; /* WYSWYG Editor? */
}
@media (max-width: 1024px) {
    .mobile-gutter {
        padding: 16px 0px 0px 0px;
    }
}
</style>
