import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "contact-info-container" }

import { useDonationStore } from "@/stores/DonationStore";
import useVuelidate from "@vuelidate/core";
import { email, required, requiredIf, helpers, maxLength } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { AutoCap, FormatPostalCode, FormatProvince } from "../utils/StringFunctions";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { useSiteSettings } from "@/stores/SiteSettingsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'DonorInformation',
  setup(__props) {

const donationStore = useDonationStore();
const {
    FirstName,
    LastName,
    Email,
    PhoneNumber,
    SelectedPhoneNumberType,
    PhoneNumberTypes,
    PhoneExtension,
    StreetAddress,
    City,
    ProvinceState,
    PostalCode,
    Country,
    BillingStreetAddress,
    BillingCity,
    BillingProvinceState,
    BillingCountry,
    BillingPostalCode,
    UseDifferentAddress,
    paymentMethod,
} = storeToRefs(donationStore);

const siteSettingsStore = useSiteSettings();
const { CountryCodes } = storeToRefs(siteSettingsStore);

SelectedPhoneNumberType.value = "Cell";

const validations = computed(() => ({
    FirstName: {
        required: helpers.withMessage("First name is required", required),
        maxLength: helpers.withMessage("This field has a character limit of 100.", maxLength(100)),
    },
    LastName: {
        required: helpers.withMessage("Last name is required", required),
        maxLength: helpers.withMessage("This field has a character limit of 100.", maxLength(100)),
    },
    Email: {
        required: helpers.withMessage("Email is required", required),
        email,
        maxLength: helpers.withMessage("This field has a character limit of 250.", maxLength(250)),
    },
    StreetAddress: { required },
    City: { required },
    ProvinceState: { required },
    PostalCode: { required },
    Country: { required },
    BillingStreetAddress: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingCity: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingProvinceState: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingPostalCode: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingCountry: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
}));

function writeOdinField(newValue: string, field: "street" | "city" | "postal") {
    if (paymentMethod.value.Processor?.Processor !== Gateway.ProcessorType.Odin) return;
    const odinElement: HTMLInputElement | null = document.querySelector(`[aria-label="odin-${field}"]`);
    if (!odinElement) return;
    odinElement.value = newValue;
    odinElement.dispatchEvent(new Event("keyup"));
}

const vuelidate = useVuelidate(validations, {
    FirstName,
    LastName,
    Email,
    StreetAddress,
    City,
    ProvinceState,
    PostalCode,
    Country,
    BillingStreetAddress,
    BillingCity,
    BillingProvinceState,
    BillingPostalCode,
    BillingCountry,
}) as any;

function phoneTypeUpdated(newValue: string) {
    SelectedPhoneNumberType.value = newValue;
}

function phoneExtensionUpdated(newValue: string) {
    PhoneExtension.value = newValue;
}

return (_ctx: any,_cache: any) => {
  const _component_cb_input = _resolveComponent("cb-input")!
  const _component_cb_col = _resolveComponent("cb-col")!
  const _component_cb_row = _resolveComponent("cb-row")!
  const _component_cb_email_input = _resolveComponent("cb-email-input")!
  const _component_cb_phone_input = _resolveComponent("cb-phone-input")!
  const _component_cb_checkbox_input = _resolveComponent("cb-checkbox-input")!
  const _component_cb_card = _resolveComponent("cb-card")!

  return (_openBlock(), _createBlock(_component_cb_card, {
    rounded: true,
    "big-rounded": true,
    class: _normalizeClass(['contact-container', _unref(UseDifferentAddress) ? 'checked' : ''])
  }, {
    default: _withCtx(() => [
      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "section-title" }, "Donor Information", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cb_row, { gutter: "sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_cb_col, {
              desktop: "6",
              tablet: "6",
              phone: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cb_input, {
                  modelValue: _unref(FirstName),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(FirstName) ? (FirstName).value = $event : null)),
                  validate: _unref(vuelidate).FirstName,
                  error: _unref(vuelidate).FirstName.$error,
                  label: "First Name"
                }, null, 8, ["modelValue", "validate", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_cb_col, {
              desktop: "6",
              tablet: "6",
              phone: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cb_input, {
                  modelValue: _unref(LastName),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(LastName) ? (LastName).value = $event : null)),
                  validate: _unref(vuelidate).LastName,
                  error: _unref(vuelidate).LastName.$error,
                  label: "Last Name"
                }, null, 8, ["modelValue", "validate", "error"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_cb_row, { gutter: "sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_cb_col, {
              desktop: "6",
              tablet: "6",
              phone: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cb_email_input, {
                  modelValue: _unref(Email),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(Email) ? (Email).value = $event : null)),
                  class: "col-3",
                  validate: _unref(vuelidate).Email,
                  error: _unref(vuelidate).Email.$error,
                  label: "Email"
                }, null, 8, ["modelValue", "validate", "error"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_cb_row, { gutter: "sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_cb_col, {
              desktop: "12",
              tablet: "12",
              phone: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cb_phone_input, {
                  id: "donorPhoneNumber",
                  modelValue: _unref(PhoneNumber),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(PhoneNumber) ? (PhoneNumber).value = $event : null)),
                  phoneType: _unref(SelectedPhoneNumberType),
                  "onUpdate:phoneType": [
                    _cache[4] || (_cache[4] = ($event: any) => (_isRef(SelectedPhoneNumberType) ? (SelectedPhoneNumberType).value = $event : null)),
                    phoneTypeUpdated
                  ],
                  extension: _unref(PhoneExtension),
                  "onUpdate:extension": [
                    _cache[5] || (_cache[5] = ($event: any) => (_isRef(PhoneExtension) ? (PhoneExtension).value = $event : null)),
                    phoneExtensionUpdated
                  ],
                  "phone-type-options": _unref(PhoneNumberTypes),
                  class: "cb-phone-input",
                  "phone-show-type": true,
                  label: "Phone",
                  name: "phone",
                  "show-extension": true,
                  "default-country-codes": _unref(CountryCodes),
                  validate: _unref(vuelidate).PhoneNumber,
                  "validate-on-blur": false
                }, null, 8, ["modelValue", "phoneType", "extension", "phone-type-options", "default-country-codes", "validate"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[32] || (_cache[32] = _createElementVNode("div", { class: "section-subtitle section-padding" }, "Mailing Address", -1)),
      _createVNode(_component_cb_row, {
        gutter: "sm",
        style: {"padding-top":"4px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cb_col, {
            desktop: "7",
            tablet: "7",
            phone: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_input, {
                modelValue: _unref(StreetAddress),
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(StreetAddress) ? (StreetAddress).value = $event : null)),
                error: _unref(vuelidate).StreetAddress.$error,
                errorMessage: "Street address is required",
                label: "Street Address or PO Box",
                autocomplete: "shipping street-address",
                class: "street-address",
                onKeyup: _cache[7] || (_cache[7] = ($event: any) => (writeOdinField($event.target.value, 'street')))
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_cb_col, {
            desktop: "5",
            tablet: "5",
            phone: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_input, {
                modelValue: _unref(City),
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(City) ? (City).value = $event : null)),
                error: _unref(vuelidate).City.$error,
                errorMessage: "City is required",
                label: "City/Town",
                autocomplete: "shipping address-level2",
                class: "city-town",
                onChange: _cache[9] || (_cache[9] = ($event: any) => (City.value = _unref(AutoCap)(_unref(City)))),
                onKeyup: _cache[10] || (_cache[10] = ($event: any) => (writeOdinField($event.target.value, 'city')))
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_cb_row, { gutter: "sm" }, {
        default: _withCtx(() => [
          _createVNode(_component_cb_col, {
            desktop: "4",
            tablet: "4",
            phone: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_input, {
                modelValue: _unref(ProvinceState),
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_isRef(ProvinceState) ? (ProvinceState).value = $event : null)),
                error: _unref(vuelidate).ProvinceState.$error,
                errorMessage: "Field is required",
                label: "State/Province",
                autocomplete: "shipping province state",
                class: "state-province",
                onChange: _cache[12] || (_cache[12] = ($event: any) => (ProvinceState.value = _unref(FormatProvince)(_unref(ProvinceState))))
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_cb_col, {
            desktop: "4",
            tablet: "4",
            phone: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_input, {
                modelValue: _unref(PostalCode),
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_isRef(PostalCode) ? (PostalCode).value = $event : null)),
                error: _unref(vuelidate).PostalCode.$error,
                errorMessage: "Field is required",
                label: "Zip/Postal Code",
                autocomplete: "shipping postal-code",
                class: "postal-code",
                onChange: _cache[14] || (_cache[14] = ($event: any) => (PostalCode.value = _unref(FormatPostalCode)(_unref(PostalCode)))),
                onKeyup: _cache[15] || (_cache[15] = ($event: any) => (writeOdinField($event.target.value, 'postal')))
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_cb_col, {
            desktop: "4",
            tablet: "4",
            phone: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_input, {
                modelValue: _unref(Country),
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_isRef(Country) ? (Country).value = $event : null)),
                error: _unref(vuelidate).Country.$error,
                errorMessage: "Country is required",
                label: "Country",
                autocomplete: "shipping country",
                onChange: _cache[17] || (_cache[17] = ($event: any) => (Country.value = _unref(AutoCap)(_unref(Country))))
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _cache[33] || (_cache[33] = _createElementVNode("div", {
        class: "section-subtitle",
        style: {"padding-top":"16px"}
      }, "Billing Address", -1)),
      _createVNode(_component_cb_row, { class: "billing-checkbox" }, {
        default: _withCtx(() => [
          _createVNode(_component_cb_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_cb_checkbox_input, {
                modelValue: _unref(UseDifferentAddress),
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_isRef(UseDifferentAddress) ? (UseDifferentAddress).value = $event : null)),
                label: "Use a different address for billing",
                name: "billing",
                color: "accent",
                val: false
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_unref(UseDifferentAddress))
        ? (_openBlock(), _createBlock(_component_cb_row, {
            key: 0,
            gutter: "sm"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_col, {
                desktop: "6",
                tablet: "6",
                phone: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cb_input, {
                    modelValue: _unref(BillingStreetAddress),
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_isRef(BillingStreetAddress) ? (BillingStreetAddress).value = $event : null)),
                    error: _unref(vuelidate).BillingStreetAddress.$error,
                    errorMessage: "Street address is required",
                    label: "Street Address or PO Box",
                    autocomplete: "shipping street-address",
                    class: "street-address",
                    onKeyup: _cache[20] || (_cache[20] = ($event: any) => (writeOdinField($event.target.value, 'street')))
                  }, null, 8, ["modelValue", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_cb_col, {
                desktop: "6",
                tablet: "6",
                phone: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cb_input, {
                    modelValue: _unref(BillingCity),
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_isRef(BillingCity) ? (BillingCity).value = $event : null)),
                    error: _unref(vuelidate).BillingCity.$error,
                    errorMessage: "City is required",
                    label: "City/Town",
                    autocomplete: "shipping address-level2",
                    class: "city-town",
                    onChange: _cache[22] || (_cache[22] = ($event: any) => (BillingCity.value = _unref(AutoCap)(_unref(BillingCity)))),
                    onKeyup: _cache[23] || (_cache[23] = ($event: any) => (writeOdinField($event.target.value, 'city')))
                  }, null, 8, ["modelValue", "error"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(UseDifferentAddress))
        ? (_openBlock(), _createBlock(_component_cb_row, {
            key: 1,
            gutter: "sm"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_col, {
                desktop: "3",
                tablet: "3",
                phone: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cb_input, {
                    modelValue: _unref(BillingProvinceState),
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (_isRef(BillingProvinceState) ? (BillingProvinceState).value = $event : null)),
                    error: _unref(vuelidate).BillingProvinceState.$error,
                    errorMessage: "Field is required",
                    label: "State/Province",
                    autocomplete: "shipping province state",
                    class: "state-province",
                    onChange: _cache[25] || (_cache[25] = ($event: any) => (BillingProvinceState.value = _unref(FormatProvince)(_unref(BillingProvinceState))))
                  }, null, 8, ["modelValue", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_cb_col, {
                desktop: "3",
                tablet: "3",
                phone: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cb_input, {
                    modelValue: _unref(BillingPostalCode),
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (_isRef(BillingPostalCode) ? (BillingPostalCode).value = $event : null)),
                    error: _unref(vuelidate).BillingPostalCode.$error,
                    errorMessage: "Field is required",
                    label: "Zip/Postal Code",
                    autocomplete: "shipping postal-code",
                    class: "postal-code",
                    onChange: _cache[27] || (_cache[27] = ($event: any) => (BillingPostalCode.value = _unref(FormatPostalCode)(_unref(BillingPostalCode)))),
                    onKeyup: _cache[28] || (_cache[28] = ($event: any) => (writeOdinField($event.target.value, 'postal')))
                  }, null, 8, ["modelValue", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_cb_col, {
                desktop: "6",
                tablet: "6",
                phone: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cb_input, {
                    modelValue: _unref(BillingCountry),
                    "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => (_isRef(BillingCountry) ? (BillingCountry).value = $event : null)),
                    name: "country",
                    error: _unref(vuelidate).BillingCountry.$error,
                    errorMessage: "Country is required",
                    label: "Country",
                    autocomplete: "shipping country",
                    onChange: _cache[30] || (_cache[30] = ($event: any) => (BillingCountry.value = _unref(AutoCap)(_unref(BillingCountry))))
                  }, null, 8, ["modelValue", "error"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})