import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";

export class GivingOption {
    public Name: string;
    public Id: string;
    public Description: string;
    public Order: number;
    public DonationAttribution: Gateway.DonationDedicationType;
    public DonationAttributionName: string;

    public constructor(option: Gateway.GivingOptionDTO) {
        this.Name = option.Name;
        this.Id = option.Id;
        this.Description = option.Description;
        this.Order = option.Order;
        this.DonationAttribution = option.DonationAttribution;
        this.DonationAttributionName = option.DonationAttributionName;
    }
}
