import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";

export class DonationAttributionType {
    public Name: string;
    public Value: Gateway.DonationDedicationType;

    public constructor(name: string, value: Gateway.DonationDedicationType) {
        this.Name = name;
        this.Value = value;
    }
}
