import { DonationAttributionType } from "@/models/DonationAttributionType";
import { GivingOption } from "@/models/GivingOption";
import { GetOrdinalForDayOfTheMonth } from "@/utils/StringFunctions";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { defineStore } from "pinia";

export const useDonationStore = defineStore("donationStore", {
    state: () => ({
        DonationAttempts: 0,
        //donation amount
        DonationAmount: null,
        DonationNote: null as string,

        //giving option
        GivingOptions: [] as GivingOption[],
        SelectedGivingOptionId: "",
        SelectedDonationAttributionOption: {} as Gateway.DonationDedicationType,
        DonationAttributionName: "",

        DonationAttributionOptions: [
            new DonationAttributionType("No Dedication", Gateway.DonationDedicationType.NoDedication),
            new DonationAttributionType("In Memory Of", Gateway.DonationDedicationType.InMemoryOf),
            new DonationAttributionType("In Honor Of", Gateway.DonationDedicationType.InHonourOf),
        ],

        //donor info
        FirstName: null as string,
        LastName: null as string,
        Email: null as string,
        PhoneNumber: null as string,
        PhoneNumberTypes: ["Cell", "Home", "Other"] as string[],
        SelectedPhoneNumberType: "",
        PhoneExtension: null as string,

        StreetAddress: null as string,
        City: null as string,
        ProvinceState: null as string,
        Country: null as string,
        PostalCode: null as string,

        BillingStreetAddress: null as string,
        BillingCity: null as string,
        BillingProvinceState: null as string,
        BillingCountry: null as string,
        BillingPostalCode: null as string,
        UseDifferentAddress: false,

        //PaymentInfo
        CardholderName: null as string,
        IsSubmittingPayment: false,
        PaymentDeclined: false,
        Token: null as string,
        SubmitPaymentClicked: false,

        //misc
        UseCanadianSpelling: false,
        ByPassCaptcha: false,
        RecaptchaToken: null as string,

        ShowBamboraError: false,

        PreviousSubmissionDeclinedDonationId: "",

        ShowConfirmation: false,

        IsLoading: false,

        IsRecurringDonation: false,
        DayOfRecurrence: new Date().getDate(),

        paymentMethod: {} as Gateway.PaymentMethodDTO,
    }),
    getters: {
        SelectedGivingOption(): GivingOption {
            if (this.GivingOptions.length > 1) {
                return this.GivingOptions.filter((opt) => opt.Id === this.SelectedGivingOptionId)[0];
            } else {
                return this.GivingOptions[0];
            }
        },
        DedicationString(): string {
            if (this.SelectedDonationAttributionOption) {
                const type = this.DonationAttributionOptions.find(
                    (attributionOption) => attributionOption.Value === this.SelectedDonationAttributionOption,
                );
                if (type) {
                    return type.Name;
                }
            }
            return "";
        },
        CalculatedRecurrenceDate(): Date {
            const currentDate = new Date();
            let scheduledDate = new Date();

            if (currentDate.getDate() < this.DayOfRecurrence) {
                //if the date is later this month
                scheduledDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), this.DayOfRecurrence);
            } else if (currentDate.getDate() > this.DayOfRecurrence) {
                //if the date is next month
                const daysNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
                if (this.DayOfRecurrence <= daysNextMonth) {
                    //if the day exists next month, fast forward
                    scheduledDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, this.DayOfRecurrence);
                } else {
                    //set it to the end of the month
                    scheduledDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, daysNextMonth);
                }
            } else {
                //if the date is today
                scheduledDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            }

            return scheduledDate;
        },
        IsDayOfRecurrenceToday(): boolean {
            return this.DayOfRecurrence === new Date().getDate();
        },
        DayOfRecurrenceOrdinal(): string {
            return GetOrdinalForDayOfTheMonth(this.DayOfRecurrence);
        },
    },
    actions: {
        async GetGivingOptions() {
            const response = await Gateway.ApiGateway.GetGivingOptions();
            this.GivingOptions = response.data.map((option: Gateway.GivingOptionDTO) => {
                return new GivingOption(option);
            });
            this.IsLoading = false;
        },
        async GetDonationAttributingSpelling() {
            const response = await Gateway.ApiGateway.GetDisplayCanadianSpelling();
            this.UseCanadianSpelling = response.data;
            this.DonationAttributionOptions = [
                new DonationAttributionType("No Dedication", Gateway.DonationDedicationType.NoDedication),
                new DonationAttributionType("In Memory Of", Gateway.DonationDedicationType.InMemoryOf),
                new DonationAttributionType(
                    `${this.UseCanadianSpelling ? "In Honour Of" : "In Honor Of"}`,
                    Gateway.DonationDedicationType.InHonourOf,
                ),
            ];
        },
        ToDTO(): Gateway.DonationSubmissionDTO {
            const dto: Gateway.DonationSubmissionDTO = <Gateway.DonationSubmissionDTO>{};

            dto.Amount = this.DonationAmount;
            dto.SelectedGivingOptionId = this.SelectedGivingOption.Id;
            dto.DonationNote = this.DonationNote;

            if (this.SelectedGivingOption.DonationAttribution !== Gateway.DonationDedicationType.NoDedication) {
                dto.Attribution = this.SelectedGivingOption.DonationAttribution;
                dto.AttributedTo = this.SelectedGivingOption.DonationAttributionName;
            } else {
                dto.Attribution = this.SelectedDonationAttributionOption;
                dto.AttributedTo = this.DonationAttributionName;
            }

            dto.FirstName = this.FirstName;
            dto.LastName = this.LastName;
            dto.Email = this.Email;
            if (this.PhoneNumber != "" && this.PhoneNumber != null) {
                dto.PhoneNumber = this.PhoneNumber;
                dto.PhoneNumberType = this.SelectedPhoneNumberType;
                dto.PhoneExtension = this.PhoneExtension;
            } else {
                //dto.PhoneNumberType = null;
            }

            dto.StreetAddress = this.StreetAddress;
            dto.Country = this.Country;
            dto.State = this.ProvinceState;
            dto.City = this.City;
            dto.PostalCode = this.PostalCode;

            dto.BillingAddress = this.BillingStreetAddress;
            dto.BillingCity = this.BillingCity;
            dto.BillingState = this.BillingProvinceState;
            dto.BillingCountry = this.BillingCountry;
            dto.BillingPostalCode = this.BillingPostalCode;
            dto.UseBillingAddress = this.UseDifferentAddress;

            dto.CardHolderName = this.CardholderName;
            dto.Token = this.Token;

            dto.RecaptchaToken = this.RecaptchaToken;

            dto.PreviousSubmissionDeclinedDonationId = this.PreviousSubmissionDeclinedDonationId;

            dto.IsRecurring = this.IsRecurringDonation;
            dto.DayOfRecurrence = this.DayOfRecurrence;
            return dto;
        },
        async getPaymentMethods() {
            try {
                const response = await Gateway.ApiGateway.PaymentMethod();
                this.paymentMethod = response.data;
            } catch (error) {
                console.error("PaymentMethod: ", error);
            }
        },
    },
});
