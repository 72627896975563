import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 1,
  label: "Dedication Information"
}

import { GivingOption } from "@/models/GivingOption";
import { useDonationStore } from "@/stores/DonationStore";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { storeToRefs } from "pinia";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DirectYourDonation',
  setup(__props) {

const donationsStore = useDonationStore();
const { SelectedGivingOptionId, SelectedDonationAttributionOption, DonationAttributionName, GivingOptions, DonationAttributionOptions } =
    storeToRefs(donationsStore);

SelectedDonationAttributionOption.value = DonationAttributionOptions.value[0].Value;

const SelectedGivingOption = computed<GivingOption>(() => {
    if (GivingOptions.value.length > 1) {
        return GivingOptions.value.filter((opt) => opt.Id === SelectedGivingOptionId.value)[0];
    } else {
        return GivingOptions.value[0];
    }
});

const DisplayTributeOptions = computed<boolean>(() => {
    const result =
        SelectedGivingOption.value && SelectedGivingOption.value.DonationAttribution == Gateway.DonationDedicationType.NoDedication;
    return result;
});

const DisplayInMemoryOfTribute = computed<boolean>(() => {
    return SelectedGivingOption.value && SelectedDonationAttributionOption.value == Gateway.DonationDedicationType.InMemoryOf;
});

const DisplayInHonorOfTribute = computed<boolean>(() => {
    return SelectedGivingOption.value && SelectedDonationAttributionOption.value == Gateway.DonationDedicationType.InHonourOf;
});

const validations = computed(() => ({
    SelectedGivingOptionId: {
        required: requiredIf(() => {
            return GivingOptions.value.length > 1;
        }),
    },
    DonationAttributionName: {
        required: requiredIf(() => {
            return DisplayTributeOptions.value && (DisplayInMemoryOfTribute.value || DisplayInHonorOfTribute.value);
        }),
    },
}));

const vuelidate = useVuelidate(validations, {
    DonationAttributionName,
    SelectedGivingOptionId,
});

return (_ctx: any,_cache: any) => {
  const _component_cb_select = _resolveComponent("cb-select")!
  const _component_cb_col = _resolveComponent("cb-col")!
  const _component_cb_row = _resolveComponent("cb-row")!
  const _component_cb_input = _resolveComponent("cb-input")!
  const _component_cb_card = _resolveComponent("cb-card")!

  return (_openBlock(), _createBlock(_component_cb_card, {
    rounded: true,
    "big-rounded": true,
    class: "direct-donation-container"
  }, {
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "section-title" }, "Direct Your Donation", -1)),
      _createVNode(_component_cb_row, { class: "direct-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_cb_col, null, {
            default: _withCtx(() => [
              (_unref(GivingOptions).length > 1)
                ? (_openBlock(), _createBlock(_component_cb_select, {
                    key: 0,
                    modelValue: _unref(SelectedGivingOptionId),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(SelectedGivingOptionId) ? (SelectedGivingOptionId).value = $event : null)),
                    name: "",
                    label: "Please select",
                    options: _unref(GivingOptions),
                    optionValue: "Id",
                    optionLabel: "Name",
                    error: _unref(vuelidate).SelectedGivingOptionId.$error,
                    errorMessage: "Value is required",
                    "emit-value": "",
                    "map-options": ""
                  }, null, 8, ["modelValue", "options", "error"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (SelectedGivingOption.value && SelectedGivingOption.value.Description)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "donation-image-text",
            innerHTML: SelectedGivingOption.value.Description
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      (DisplayTributeOptions.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_cb_row, {
              class: "dedication-container",
              gutter: "sm"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cb_col, {
                  desktop: "4",
                  tablet: "6"
                }, {
                  default: _withCtx(() => [
                    (DisplayTributeOptions.value)
                      ? (_openBlock(), _createBlock(_component_cb_select, {
                          key: 0,
                          modelValue: _unref(SelectedDonationAttributionOption),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(SelectedDonationAttributionOption) ? (SelectedDonationAttributionOption).value = $event : null)),
                          options: _unref(DonationAttributionOptions),
                          optionLabel: "Name",
                          optionValue: "Value",
                          label: "Dedication",
                          "emit-value": "",
                          "map-options": ""
                        }, null, 8, ["modelValue", "options"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_cb_col, {
                  desktop: "6",
                  tablet: "6",
                  class: "mobile-gutter"
                }, {
                  default: _withCtx(() => [
                    (DisplayTributeOptions.value && (DisplayInMemoryOfTribute.value || DisplayInHonorOfTribute.value))
                      ? (_openBlock(), _createBlock(_component_cb_input, {
                          key: 0,
                          modelValue: _unref(DonationAttributionName),
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(DonationAttributionName) ? (DonationAttributionName).value = $event : null)),
                          class: "input-adjustment",
                          label: "Enter Name",
                          error: _unref(vuelidate).DonationAttributionName.$error,
                          errorMessage: _unref(vuelidate).DonationAttributionName.$errors[0]?.$message
                        }, null, 8, ["modelValue", "error", "errorMessage"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})