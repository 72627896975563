import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { default as CbComponents } from "@campbrain/cortex";
import { createPinia } from "pinia";

axios.interceptors.request.use((config) => {
    const subdomain: string = window.location.host.split(".")[0];
    if (process.env.NODE_ENV === "development") {
        // config.baseURL = "https://devdemo.bigbrainqa.com";
        config.baseURL = "https://" + subdomain + ".lacolhost.com:44368";
    } else {
        config.baseURL = "https://" + subdomain + "." + process.env.VUE_APP_API_ENDPOINT_URL;
    }
    return config;
});

const app = createApp(App);

const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(CbComponents as any);
app.mount("#app");

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.VUE_APP_INSIGHTS,
        /* ...Other Configuration Options... */
    },
});
appInsights.loadAppInsights();
appInsights.trackPageView();
