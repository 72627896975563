<template>
    <cb-card :rounded="true" :big-rounded="true" :class="['contact-container', UseDifferentAddress ? 'checked' : '']">
        <div class="section-title">Donor Information</div>
        <div class="contact-info-container">
            <cb-row gutter="sm">
                <cb-col desktop="6" tablet="6" phone="12">
                    <cb-input v-model="FirstName" :validate="vuelidate.FirstName" :error="vuelidate.FirstName.$error" label="First Name" />
                </cb-col>
                <cb-col desktop="6" tablet="6" phone="12">
                    <cb-input v-model="LastName" :validate="vuelidate.LastName" :error="vuelidate.LastName.$error" label="Last Name" />
                </cb-col>
            </cb-row>
            <cb-row gutter="sm">
                <cb-col desktop="6" tablet="6" phone="12">
                    <cb-email-input
                        v-model="Email"
                        class="col-3"
                        :validate="vuelidate.Email"
                        :error="vuelidate.Email.$error"
                        label="Email"
                    />
                </cb-col>
            </cb-row>
            <cb-row gutter="sm">
                <cb-col desktop="12" tablet="12" phone="12">
                    <cb-phone-input
                        id="donorPhoneNumber"
                        v-model="PhoneNumber"
                        v-model:phoneType="SelectedPhoneNumberType"
                        v-model:extension="PhoneExtension"
                        :phone-type-options="PhoneNumberTypes"
                        class="cb-phone-input"
                        :phone-show-type="true"
                        label="Phone"
                        name="phone"
                        :show-extension="true"
                        :default-country-codes="CountryCodes"
                        :validate="vuelidate.PhoneNumber"
                        :validate-on-blur="false"
                        @update:phoneType="phoneTypeUpdated"
                        @update:extension="phoneExtensionUpdated"
                    />
                </cb-col>
            </cb-row>
        </div>

        <div class="section-subtitle section-padding">Mailing Address</div>
        <cb-row gutter="sm" style="padding-top: 4px">
            <cb-col desktop="7" tablet="7" phone="12">
                <cb-input
                    v-model="StreetAddress"
                    :error="vuelidate.StreetAddress.$error"
                    errorMessage="Street address is required"
                    label="Street Address or PO Box"
                    autocomplete="shipping street-address"
                    class="street-address"
                    @keyup="writeOdinField($event.target.value, 'street')"
                />
            </cb-col>

            <cb-col desktop="5" tablet="5" phone="12">
                <cb-input
                    v-model="City"
                    :error="vuelidate.City.$error"
                    errorMessage="City is required"
                    label="City/Town"
                    autocomplete="shipping address-level2"
                    class="city-town"
                    @change="City = AutoCap(City)"
                    @keyup="writeOdinField($event.target.value, 'city')"
                />
            </cb-col>
        </cb-row>
        <cb-row gutter="sm">
            <cb-col desktop="4" tablet="4" phone="12">
                <cb-input
                    v-model="ProvinceState"
                    :error="vuelidate.ProvinceState.$error"
                    errorMessage="Field is required"
                    label="State/Province"
                    autocomplete="shipping province state"
                    class="state-province"
                    @change="ProvinceState = FormatProvince(ProvinceState)"
                />
            </cb-col>
            <cb-col desktop="4" tablet="4" phone="12">
                <cb-input
                    v-model="PostalCode"
                    :error="vuelidate.PostalCode.$error"
                    errorMessage="Field is required"
                    label="Zip/Postal Code"
                    autocomplete="shipping postal-code"
                    class="postal-code"
                    @change="PostalCode = FormatPostalCode(PostalCode)"
                    @keyup="writeOdinField($event.target.value, 'postal')"
                />
            </cb-col>
            <cb-col desktop="4" tablet="4" phone="12">
                <cb-input
                    v-model="Country"
                    :error="vuelidate.Country.$error"
                    errorMessage="Country is required"
                    label="Country"
                    autocomplete="shipping country"
                    @change="Country = AutoCap(Country)"
                />
            </cb-col>
        </cb-row>

        <div class="section-subtitle" style="padding-top: 16px">Billing Address</div>
        <cb-row class="billing-checkbox">
            <cb-col>
                <cb-checkbox-input
                    v-model="UseDifferentAddress"
                    label="Use a different address for billing"
                    name="billing"
                    color="accent"
                    :val="false"
                />
            </cb-col>
        </cb-row>

        <cb-row v-if="UseDifferentAddress" gutter="sm">
            <cb-col desktop="6" tablet="6" phone="12">
                <cb-input
                    v-model="BillingStreetAddress"
                    :error="vuelidate.BillingStreetAddress.$error"
                    errorMessage="Street address is required"
                    label="Street Address or PO Box"
                    autocomplete="shipping street-address"
                    class="street-address"
                    @keyup="writeOdinField($event.target.value, 'street')"
                />
            </cb-col>
            <cb-col desktop="6" tablet="6" phone="12">
                <cb-input
                    v-model="BillingCity"
                    :error="vuelidate.BillingCity.$error"
                    errorMessage="City is required"
                    label="City/Town"
                    autocomplete="shipping address-level2"
                    class="city-town"
                    @change="BillingCity = AutoCap(BillingCity)"
                    @keyup="writeOdinField($event.target.value, 'city')"
                />
            </cb-col>
        </cb-row>
        <cb-row v-if="UseDifferentAddress" gutter="sm">
            <cb-col desktop="3" tablet="3" phone="6">
                <cb-input
                    v-model="BillingProvinceState"
                    :error="vuelidate.BillingProvinceState.$error"
                    errorMessage="Field is required"
                    label="State/Province"
                    autocomplete="shipping province state"
                    class="state-province"
                    @change="BillingProvinceState = FormatProvince(BillingProvinceState)"
                />
            </cb-col>
            <cb-col desktop="3" tablet="3" phone="6">
                <cb-input
                    v-model="BillingPostalCode"
                    :error="vuelidate.BillingPostalCode.$error"
                    errorMessage="Field is required"
                    label="Zip/Postal Code"
                    autocomplete="shipping postal-code"
                    class="postal-code"
                    @change="BillingPostalCode = FormatPostalCode(BillingPostalCode)"
                    @keyup="writeOdinField($event.target.value, 'postal')"
                />
            </cb-col>
            <cb-col desktop="6" tablet="6" phone="12">
                <cb-input
                    v-model="BillingCountry"
                    name="country"
                    :error="vuelidate.BillingCountry.$error"
                    errorMessage="Country is required"
                    label="Country"
                    autocomplete="shipping country"
                    @change="BillingCountry = AutoCap(BillingCountry)"
                />
            </cb-col>
        </cb-row>
    </cb-card>
</template>
<script setup lang="ts">
import { useDonationStore } from "@/stores/DonationStore";
import useVuelidate from "@vuelidate/core";
import { email, required, requiredIf, helpers, maxLength } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { AutoCap, FormatPostalCode, FormatProvince } from "../utils/StringFunctions";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { useSiteSettings } from "@/stores/SiteSettingsStore";

const donationStore = useDonationStore();
const {
    FirstName,
    LastName,
    Email,
    PhoneNumber,
    SelectedPhoneNumberType,
    PhoneNumberTypes,
    PhoneExtension,
    StreetAddress,
    City,
    ProvinceState,
    PostalCode,
    Country,
    BillingStreetAddress,
    BillingCity,
    BillingProvinceState,
    BillingCountry,
    BillingPostalCode,
    UseDifferentAddress,
    paymentMethod,
} = storeToRefs(donationStore);

const siteSettingsStore = useSiteSettings();
const { CountryCodes } = storeToRefs(siteSettingsStore);

SelectedPhoneNumberType.value = "Cell";

const validations = computed(() => ({
    FirstName: {
        required: helpers.withMessage("First name is required", required),
        maxLength: helpers.withMessage("This field has a character limit of 100.", maxLength(100)),
    },
    LastName: {
        required: helpers.withMessage("Last name is required", required),
        maxLength: helpers.withMessage("This field has a character limit of 100.", maxLength(100)),
    },
    Email: {
        required: helpers.withMessage("Email is required", required),
        email,
        maxLength: helpers.withMessage("This field has a character limit of 250.", maxLength(250)),
    },
    StreetAddress: { required },
    City: { required },
    ProvinceState: { required },
    PostalCode: { required },
    Country: { required },
    BillingStreetAddress: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingCity: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingProvinceState: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingPostalCode: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
    BillingCountry: {
        required: requiredIf(() => {
            return UseDifferentAddress.value;
        }),
    },
}));

function writeOdinField(newValue: string, field: "street" | "city" | "postal") {
    if (paymentMethod.value.Processor?.Processor !== Gateway.ProcessorType.Odin) return;
    const odinElement: HTMLInputElement | null = document.querySelector(`[aria-label="odin-${field}"]`);
    if (!odinElement) return;
    odinElement.value = newValue;
    odinElement.dispatchEvent(new Event("keyup"));
}

const vuelidate = useVuelidate(validations, {
    FirstName,
    LastName,
    Email,
    StreetAddress,
    City,
    ProvinceState,
    PostalCode,
    Country,
    BillingStreetAddress,
    BillingCity,
    BillingProvinceState,
    BillingPostalCode,
    BillingCountry,
}) as any;

function phoneTypeUpdated(newValue: string) {
    SelectedPhoneNumberType.value = newValue;
}

function phoneExtensionUpdated(newValue: string) {
    PhoneExtension.value = newValue;
}
</script>

<style scoped>
.contact-container {
    padding: 24px 24px 18px 24px;
}
.contact-container.checked {
    padding: 24px 24px 4px 24px;
}
.contact-info-container {
    padding: 16px 0px 4px 0px;
}
.billing-checkbox {
    font-size: 15px;
    margin-left: -10px;
}

.billing-checkbox :deep(.q-checkbox__inner--falsy .q-checkbox__bg) {
    border-color: #bdbdbd !important;
}
.cb-phone-input :deep(.phone-type .q-field__label) {
    font-size: 11px;
}
/* Mobile */
@media (max-width: 1024px) {
    .section-padding {
        margin-top: 16px;
    }
}
</style>
