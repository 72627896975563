<template>
    <div v-if="IsLoading">
        <cb-spinner />
    </div>
    <div v-if="!IsLoading && GivingOptions && GivingOptions.length > 0 && SiteHasPaymentOption">
        <form ref="form" @submit.prevent="submitForm">
            <div class="main-description" v-html="Description"></div>
            <DonationInformation />
            <br />
            <DirectYourDonation />
            <br />
            <DonorInformation />
            <div>
                <br />
                <cb-card rounded big-rounded class="payment-container">
                    <div class="section-title padding-bottom-16">Payment Information</div>
                    <cb-row>
                        <cb-col>
                            <cb-alert
                                v-if="ShowBamboraError"
                                icon="fas fa-exclamation-triangle"
                                color="orange"
                                dismissible
                                @dismiss="ShowBamboraError = false"
                            >
                                {{ ErrorMessage }}
                            </cb-alert>
                        </cb-col>
                    </cb-row>
                    <cb-row v-if="creditCardComponentReady">
                        <cb-col :class="currentProcessor === Gateway.ProcessorType.Odin ? 'odin' : ''">
                            <cb-pay-field
                                v-if="!formDisabled"
                                ref="PayField"
                                v-model:tokenValue="Token"
                                v-model:cardholderName="CardholderName"
                                :errorCardholder="vuelidate.CardholderName.$error"
                                :errorToken="vuelidate.Token.$error"
                                twoRows
                                :publicToken="publicToken"
                                :processor="currentProcessor"
                                @odinResult="checkOdinResult"
                            />
                        </cb-col>
                    </cb-row>
                    <cb-spinner v-else />
                </cb-card>
                <br />
                <hr />
                <cb-button
                    v-if="ByPassCaptcha"
                    color="accent"
                    label="Review and Submit"
                    :disabled="formDisabled"
                    :loading="isOdin && waitingOdinResponse"
                    type="submit"
                    @click="ValidateOnSubmit"
                />
                <cb-button
                    v-else
                    id="CaptchaSubmit"
                    :loading="isOdin && waitingOdinResponse"
                    color="accent"
                    label="Review and Submit"
                    :disabled="formDisabled"
                    type="submit"
                    @click="FireCaptcha"
                />
                <ConfirmationDialog
                    :ButtonTextColour="ButtonTextColour"
                    :DonationAmount="DonationAmount"
                    :Email="Email"
                    :GivingOption="SelectedGivingOption && SelectedGivingOption.Name"
                    :IsSubmittingPayment="IsSubmittingPayment"
                    :PaymentDeclined="PaymentDeclined"
                    @DonationSubmitted="OnSubmitted"
                    @closeDialog="closeDialog"
                />
                <div v-if="!ByPassCaptcha">
                    <vue-hcaptcha
                        ref="invisibleHcaptcha"
                        :sitekey="siteSettingsStore.CaptchaSiteKey"
                        size="invisible"
                        @verify="OnCaptchaVerified"
                        @expired="OnCaptchaExpired"
                        @challenge-expired="OnCaptchaExpired"
                    />
                </div>
            </div>
        </form>
        <div class="caption">
            <p style="margin: 16px 0px 0px">
                By submitting your donation, you agree to our
                <span style="white-space: nowrap">
                    <a href="https://www.campbrain.com/olrterms" target="_blank" class="text-accent">Terms</a> and
                    <a href="https://www.campbrain.com/olrprivacy" target="_blank" class="text-accent" style="white-space: nowrap"
                        >Privacy Policy</a
                    >.
                </span>
            </p>
            <p>
                This site is protected by <a href="https://www.hCaptcha.com" target="_blank">hCaptcha</a> and its
                <a href="https://www.hcaptcha.com/privacy" target="_blank">Privacy Policy</a> and
                <a href="https://www.hcaptcha.com/terms" target="_blank">Terms of Service</a> apply.
            </p>
        </div>
        <div class="caption">
            <p style="white-space: nowrap">
                ©
                {{ new Date().getFullYear() }} BrainRunner Inc. | {{ Version }}
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ref, computed, onBeforeMount } from "vue";
import router from "@/router";

import { CBPayField, Notify } from "@campbrain/cortex";
import { useSiteSettings } from "@/stores/SiteSettingsStore";
import { storeToRefs } from "pinia";
import { useDonationStore } from "@/stores/DonationStore";

import DonorInformation from "@/components/DonorInformation.vue";
import DonationInformation from "@/components/DonationInformation.vue";
import DirectYourDonation from "@/components/DirectYourDonation.vue";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";

const invisibleHcaptcha = ref<VueHcaptcha | null>(null);

const siteSettingsStore = useSiteSettings();
const { Version, Description, ButtonTextColour, IsEnabled } = storeToRefs(siteSettingsStore);

const donationStore = useDonationStore();
const {
    DonationAttempts,
    DonationAmount,
    Email,
    SubmitPaymentClicked,
    IsLoading,
    GivingOptions,
    CardholderName,
    Token,
    SelectedGivingOption,
    IsSubmittingPayment,
    ShowBamboraError,
    ByPassCaptcha,
    RecaptchaToken,
    PaymentDeclined,
    PreviousSubmissionDeclinedDonationId,
    ShowConfirmation,
    paymentMethod,
} = storeToRefs(donationStore);

const PayField = ref<typeof CBPayField>();
const ErrorMessage = ref<string>("");
const publicToken = ref("");
const odinResults = ref();
const waitingOdinResponse = ref(false);
const currentRecaptchaKey = ref("");
const errorKey = ref("");
const formDisabled = ref(false);

const isOdin = computed(() => {
    return paymentMethod.value.Processor?.Processor === Gateway.ProcessorType.Odin;
});

const currentProcessor = computed(() => {
    if (isOdin.value) getPublicToken();
    return paymentMethod.value.Processor?.Processor;
});
const creditCardComponentReady = computed(() => {
    return (
        currentProcessor.value === Gateway.ProcessorType.Bambora ||
        (currentProcessor.value === Gateway.ProcessorType.Odin && publicToken.value != "")
    );
});

function DispatchValidation(): void {
    SubmitPaymentClicked.value = !SubmitPaymentClicked.value;
}

const SiteHasPaymentOption = computed<boolean>(() => {
    return !!siteSettingsStore.SiteSettings && !!siteSettingsStore.SiteSettings.SelectedPaymentOptionId;
});
function OnCaptchaExpired() {
    if (!ByPassCaptcha.value && invisibleHcaptcha.value) {
        invisibleHcaptcha.value.reset();
    }
}

function CheckForBypassParam(): boolean {
    const queryString: string = window.location.search.substring(1);

    const params: { [id: string]: string } = {};

    const queries = queryString.split("&");

    queries.forEach((indexQuery: string) => {
        const indexPair = indexQuery.split("=");

        const queryKey: string = decodeURIComponent(indexPair[0]);
        const queryValue: string = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : "");

        params[queryKey] = queryValue;
    });

    return params["specflowletsgo"] === "true";
}

function OnCaptchaVerified(token: string, eKey: string): void {
    errorKey.value = eKey;
    ValidateOnSubmit(token);
}

function FireCaptcha(): void {
    if (invisibleHcaptcha.value) {
        invisibleHcaptcha.value.execute();
    }
}

function closeDialog(paymentDeclined: boolean): void {
    ShowConfirmation.value = false;
    ShowBamboraError.value = paymentDeclined;
    PayField.value.refreshToken();
    OnCaptchaExpired();
}

function checkOdinResult(odinResponse) {
    odinResults.value = odinResponse;
    waitingOdinResponse.value = false;
    executeValidation(currentRecaptchaKey.value);
    if (!odinResponse.success) {
        document.getElementById("parentDivodin-card-info").style.borderColor = "red";
        document.getElementById("parentDivodin-card-info").style.borderWidth = "2px";
    }
}

function OnSubmitted(): void {
    if (Token.value == null) {
        DispatchValidation();
    } else {
        DonationAttempts.value = DonationAttempts.value + 1;
        IsSubmittingPayment.value = true;
        PaymentDeclined.value = false;
        ShowBamboraError.value = false;
        Gateway.ApiGateway.SubmitDonation(donationStore.ToDTO()).then((response) => {
            if (response.data.Success) {
                if (siteSettingsStore.SiteSettings.FacebookPixelEnabled) {
                    (<any>window).fbq("track", "Donate", {
                        value: DonationAmount,
                        currency: siteSettingsStore.SiteSettings.FacebookPixelCurrency,
                    });
                }
                router.push({
                    name: "Confirmation",
                });
            } else {
                PaymentDeclined.value = true;
                OnCaptchaExpired();
                PreviousSubmissionDeclinedDonationId.value = response.data.Id;
                ErrorMessage.value = "The transaction could not be completed. Reason: " + response.data.Exception;
                PayField.value.refreshToken();
            }
            IsSubmittingPayment.value = false;
        });
    }
}

function executeValidation(recaptchaToken: string = "") {
    if (validateForm() && ByPassCaptcha.value) {
        PayField.value.refreshToken();
        PaymentDeclined.value = false;
        ShowConfirmation.value = true;
    } else if (validateForm() && !ByPassCaptcha.value) {
        PayField.value.refreshToken();
        PaymentDeclined.value = false;
        RecaptchaToken.value = recaptchaToken;
        ShowConfirmation.value = true;
    } else {
        if (!ByPassCaptcha.value) {
            OnCaptchaExpired();
        }
        setTimeout(() => {
            ScrollToError();
        }, 100);
    }
}

function ValidateOnSubmit(recaptchaToken: string): void {
    currentRecaptchaKey.value = recaptchaToken;

    if (DonationAttempts.value >= process.env.VUE_APP_MAX_DONATION_ATTEMPTS) {
        formDisabled.value = true;
        Notify.create({
            //Generic System Error
            message:
                "We were unable to process your donation and you have exceeded the number of retries available. Refresh the page to try again.",
            type: "cb-error",
            icon: "",
            group: false,
            position: "center",
            multiLine: true,
            timeout: 0,
            html: true,
            actions: [
                {
                    label: "Refresh",
                    color: "red-10",
                    handler: () => {
                        window.location.reload();
                    },
                },
            ],
        });

        return;
    }

    DispatchValidation();
    if (currentProcessor.value !== Gateway.ProcessorType.Odin) {
        executeValidation(recaptchaToken);
        return;
    }

    waitingOdinResponse.value = true;
    odinResults.value = null;
    document.getElementById("odin-submit")?.click();
}

const validations = computed(() => ({
    CardholderName: { required },
    Token: { required },
}));

const vuelidate = useVuelidate(validations, {
    CardholderName,
    Token,
});

function validateForm(): boolean {
    vuelidate.value.$validate();
    if (vuelidate.value.$errors.length > 0) {
        return false;
    }
    return true;
}

function ScrollToError() {
    const el = document.querySelector(".text-negative:first-of-type");
    if (el) el.scrollIntoView();
}

ByPassCaptcha.value = CheckForBypassParam();

async function getPublicToken() {
    const result = await Gateway.ApiGateway.PaymentPublicToken(paymentMethod.value.Id);
    publicToken.value = result.data.Token;
}

onBeforeMount(async () => {
    IsLoading.value = true;
    if (IsEnabled.value) {
        await donationStore.GetGivingOptions();
        await donationStore.GetDonationAttributingSpelling();
        await donationStore.getPaymentMethods();
    }

    if (GivingOptions.value.length === 0 || !SiteHasPaymentOption.value) {
        router.push({
            name: "SiteNotEnabled",
        });
    }

    IsLoading.value = false;
});
</script>

<style scoped>
.suggested-amount {
    width: 100%;
    justify-content: center;
}

.caption {
    font-size: 12px !important;
    font-weight: 400;
    margin-bottom: 4px !important;
    color: #9e9e9e !important;
    caret-color: #9e9e9e !important;
}
.payment-container {
    padding: 24px 24px 4px 24px;
}
.main-description {
    margin: 16px 0px;
}
:deep(.cardhold-name) {
    padding-left: 0px;
}
:deep(.odin .card-number) {
    padding-left: 0px !important;
    padding-bottom: 24px;
}
</style>
