<template>
    <div>
        <div class="text-h4 text-center">Site Unavailable</div>
        <div class="text-body1 text-center">
            The site you are trying to access is unavailable at this time. For more information or to make a donation, please call our
            office.
        </div>
    </div>
</template>

<script lang="ts">
export default {};
</script>
