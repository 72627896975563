import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "review-table" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "buttons-container" }

import { storeToRefs } from "pinia";
import Events from "../models/Events";
import { useDonationStore } from "@/stores/DonationStore";
import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmationDialog',
  emits: [Events.DonationSubmitted, "closeDialog"],
  setup(__props, { emit: __emit }) {

const donationStore = useDonationStore();
const {
    ShowConfirmation,
    IsSubmittingPayment,
    PaymentDeclined,
    DonationAmount,
    Email,
    IsRecurringDonation,
    SelectedGivingOption,
    DedicationString,
    DonationAttributionName,
    DonationNote,
    CalculatedRecurrenceDate,
    IsDayOfRecurrenceToday,
    DayOfRecurrence,
    DayOfRecurrenceOrdinal,
    SelectedDonationAttributionOption,
} = storeToRefs(donationStore);

const emits = __emit;

const RecurringDonationAmount = computed<string>(() => {
    if (!IsRecurringDonation.value) {
        return "";
    }

    return `$${DonationAmount.value}/month (on the ${DayOfRecurrence.value}${DayOfRecurrenceOrdinal.value}${
        DayOfRecurrence.value > 28 ? "*" : ""
    })`;
});

const DonationAmountText = computed<string>(() => {
    return `$${DonationAmount.value}`;
});

function submit(): void {
    emits(Events.DonationSubmitted, null);
}

return (_ctx: any,_cache: any) => {
  const _component_cb_col = _resolveComponent("cb-col")!
  const _component_cb_row = _resolveComponent("cb-row")!
  const _component_cb_alert = _resolveComponent("cb-alert")!
  const _component_cb_button = _resolveComponent("cb-button")!
  const _component_cb_modal_page = _resolveComponent("cb-modal-page")!
  const _component_cb_spinner = _resolveComponent("cb-spinner")!
  const _component_cb_icon = _resolveComponent("cb-icon")!
  const _component_cb_app = _resolveComponent("cb-app")!
  const _component_cb_modal = _resolveComponent("cb-modal")!

  return (_openBlock(), _createBlock(_component_cb_modal, {
    modelValue: _unref(ShowConfirmation),
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(ShowConfirmation) ? (ShowConfirmation).value = $event : null)),
    persistent: "",
    customWidth: "300"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_cb_app, { class: "modal-container" }, {
        default: _withCtx(() => [
          (!_unref(IsSubmittingPayment) && !_unref(PaymentDeclined))
            ? (_openBlock(), _createBlock(_component_cb_modal_page, { key: 0 }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _cache[4] || (_cache[4] = _createTextVNode("Review Your")),
                    (_unref(IsRecurringDonation))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Monthly"))
                      : _createCommentVNode("", true),
                    _cache[5] || (_cache[5] = _createTextVNode(" Donation"))
                  ])
                ]),
                body: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_cb_row, { class: "table-row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_cb_col, {
                          desktop: "3",
                          tablet: "3",
                          phone: "4"
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createElementVNode("span", { class: "description" }, "Donation", -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_cb_col, {
                          desktop: "9",
                          tablet: "9",
                          phone: "8"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              textContent: _toDisplayString(_unref(IsRecurringDonation) ? RecurringDonationAmount.value : DonationAmountText.value)
                            }, null, 8, _hoisted_4)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_cb_row, { class: "table-row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_cb_col, {
                          desktop: "3",
                          tablet: "3",
                          phone: "4"
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createElementVNode("span", { class: "description" }, "Giving to", -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_cb_col, {
                          desktop: "9",
                          tablet: "9",
                          phone: "8"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              textContent: _toDisplayString(_unref(SelectedGivingOption).Name)
                            }, null, 8, _hoisted_5)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_unref(SelectedDonationAttributionOption) !== Gateway.DonationDedicationType.NoDedication)
                      ? (_openBlock(), _createBlock(_component_cb_row, {
                          key: 0,
                          class: "table-row"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_cb_col, {
                              desktop: "3",
                              tablet: "3",
                              phone: "4"
                            }, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createElementVNode("span", { class: "description" }, "Dedication", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_cb_col, {
                              desktop: "9",
                              tablet: "9",
                              phone: "8"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  textContent: _toDisplayString(`${_unref(DedicationString)}: ${_unref(DonationAttributionName)}`)
                                }, null, 8, _hoisted_6)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_unref(DonationNote))
                      ? (_openBlock(), _createBlock(_component_cb_row, {
                          key: 1,
                          class: "table-row"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_cb_col, {
                              desktop: "3",
                              tablet: "3",
                              phone: "4"
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createElementVNode("span", { class: "description" }, "Donation Note", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_cb_col, {
                              desktop: "9",
                              tablet: "9",
                              phone: "8"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  textContent: _toDisplayString(_unref(DonationNote))
                                }, null, 8, _hoisted_7)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  (_unref(IsRecurringDonation) && _unref(DayOfRecurrence) > 28)
                    ? (_openBlock(), _createBlock(_component_cb_row, { key: 0 }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("span", { class: "last-day-text" }, " *Should an upcoming month not include this date, the donation will process on the last day of that month (i.e. 29th, 30th, 31st, etc.) ", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_cb_row, {
                    class: "table-row",
                    style: {"margin-top":"16px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cb_col, {
                        desktop: "3",
                        tablet: "3",
                        phone: "4"
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("span", { class: "description" }, "Email", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_cb_col, {
                        desktop: "9",
                        tablet: "9",
                        phone: "8"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            textContent: _toDisplayString(_unref(Email))
                          }, null, 8, _hoisted_8)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", {
                    class: "separator",
                    style: _normalizeStyle(_unref(IsRecurringDonation) ? `` : `margin-bottom: 0px`)
                  }, null, 4),
                  (_unref(IsRecurringDonation))
                    ? (_openBlock(), _createBlock(_component_cb_row, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_cb_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_cb_alert, {
                                color: "accent",
                                class: "banner-style"
                              }, {
                                default: _withCtx(() => [
                                  _cache[12] || (_cache[12] = _createTextVNode(" Your first ")),
                                  _createElementVNode("b", null, [
                                    _createElementVNode("span", {
                                      textContent: _toDisplayString(DonationAmountText.value)
                                    }, null, 8, _hoisted_9)
                                  ]),
                                  _cache[13] || (_cache[13] = _createTextVNode(" donation will be processed ")),
                                  _createElementVNode("b", null, [
                                    _createElementVNode("span", {
                                      textContent: _toDisplayString(
                                            _unref(CalculatedRecurrenceDate).toLocaleDateString('en-us', {
                                                month: 'short',
                                                day: 'numeric',
                                            })
                                        )
                                    }, null, 8, _hoisted_10),
                                    (_unref(IsDayOfRecurrenceToday))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, " (today)"))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _cache[14] || (_cache[14] = _createTextVNode(". "))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_cb_button, {
                      class: "col",
                      color: "accent",
                      label: "Confirm",
                      "full-width": true,
                      onClick: submit
                    }),
                    _createVNode(_component_cb_button, {
                      label: "Back",
                      class: "col",
                      "full-width": true,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeDialog')))
                    })
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(IsSubmittingPayment))
            ? (_openBlock(), _createBlock(_component_cb_modal_page, {
                key: 1,
                style: {"text-align":"center"}
              }, {
                header: _withCtx(() => [
                  _createVNode(_component_cb_spinner)
                ]),
                body: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createElementVNode("p", null, "Submitting...", -1)
                ])),
                footer: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createElementVNode("p", null, "(This may take a moment)", -1)
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_unref(IsSubmittingPayment) && _unref(PaymentDeclined) && !_unref(IsRecurringDonation))
            ? (_openBlock(), _createBlock(_component_cb_modal_page, { key: 2 }, {
                header: _withCtx(() => [
                  _createVNode(_component_cb_icon, {
                    name: "fas fa-exclamation-triangle",
                    color: "red"
                  }),
                  _cache[17] || (_cache[17] = _createTextVNode(" We were unable to process your donation "))
                ]),
                body: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createElementVNode("p", { style: {"text-align":"center"} }, "Please check the credit card information and try again", -1)
                ])),
                footer: _withCtx(() => [
                  _createVNode(_component_cb_button, {
                    label: "Close",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeDialog', _unref(PaymentDeclined))))
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_unref(IsSubmittingPayment) && _unref(PaymentDeclined) && _unref(IsRecurringDonation))
            ? (_openBlock(), _createBlock(_component_cb_modal_page, { key: 3 }, {
                header: _withCtx(() => [
                  _createVNode(_component_cb_icon, {
                    name: "fas fa-exclamation-triangle",
                    color: "red"
                  }),
                  _cache[19] || (_cache[19] = _createTextVNode(" We were unable to process your donation "))
                ]),
                body: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createElementVNode("p", { style: {"text-align":"center"} }, "Your credit card will not be charged monthly going forward.", -1),
                  _createElementVNode("p", { style: {"text-align":"center"} }, "Please check the credit card information and try again", -1)
                ])),
                footer: _withCtx(() => [
                  _createVNode(_component_cb_button, {
                    label: "Close",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeDialog', _unref(PaymentDeclined))))
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})