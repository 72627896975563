const regexPostalCodeCanadian1 = new RegExp("^[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]$");
const regexPostalCodeCanadian2 = new RegExp("^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$");
const regexPostalCodeAmerican1 = new RegExp("^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$");
const regexPostalCodeAmerican2 = new RegExp("^[0-9][0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9]$");
const regexProvinceWith2Letters = new RegExp("^[A-Z][A-Z]$");

// Phone Number
export const FormatPhoneNumber = (input: string): string => {
    const phoneAsNumber: number = parseFloat(input);

    if (isNaN(phoneAsNumber)) {
        return input ? input.trim() : input;
    }

    if (phoneAsNumber.toString().length != input.length) {
        return input ? input.trim() : input;
    }

    const phoneParts: string[] = input.split(".");

    let returnValue: string = input;

    let formattedPhone = false;

    switch (phoneParts[0].length) {
        case 7:
            returnValue = phoneParts[0].substr(0, 3) + "-" + phoneParts[0].substr(3, 4);
            formattedPhone = true;
            break;
        case 10:
            returnValue = phoneParts[0].substr(0, 3) + "-" + phoneParts[0].substr(3, 3) + "-" + phoneParts[0].substr(6, 4);
            formattedPhone = true;
            break;
        case 11:
            returnValue =
                phoneParts[0].substr(0, 1) +
                "-" +
                phoneParts[0].substr(1, 3) +
                "-" +
                phoneParts[0].substr(4, 3) +
                "-" +
                phoneParts[0].substr(7, 4);
            formattedPhone = true;
            break;
        default:
            break;
    }

    if (formattedPhone && phoneParts.length > 1) {
        returnValue = `${returnValue} x ${phoneParts[1]}`;
    }

    return returnValue;
};

// AutoCap
export const AutoCap = (input: string): string => {
    if (!input) return input;

    //If any uppercase letters in the string, return as is.
    if (input.toLowerCase() != input) return input;

    //Capitalize first letter and everything after a carriage return, hyphen or a space
    let formattedStr = "";

    let newWord = true;
    for (let x = 0; x < input.length; x++) {
        const c: string = input.charAt(x);
        if (newWord) {
            formattedStr += c.toUpperCase();
            newWord = false;
        } else {
            formattedStr += c;
        }
        if (c == " " || c == "-" || c == "\n") {
            newWord = true;
        }
    }
    return formattedStr;
};

// Postal Code
export const FormatPostalCode = (postalCode: string): string => {
    postalCode = postalCode.trim();

    if (!postalCode) {
        return postalCode;
    }

    //M2J2R3
    if (regexPostalCodeCanadian1.test(postalCode)) {
        postalCode = `${postalCode.substring(0, 3)} ${postalCode.substr(3)}`;
        return postalCode.toUpperCase();
    }
    //M2J 2R3
    else if (regexPostalCodeCanadian2.test(postalCode)) {
        return postalCode.toUpperCase();
    }
    //902102333
    else if (regexPostalCodeAmerican1.test(postalCode)) {
        return `${postalCode.substring(0, 5)}-${postalCode.substr(5)}`;
    }
    //90210 2333
    else if (regexPostalCodeAmerican2.test(postalCode)) {
        return `${postalCode.substring(0, 5)}-${postalCode.substr(5).trim()}`;
    } else {
        return postalCode;
    }
};

export const FormatProvince = (input: string): string => {
    if (!input) {
        return input;
    }

    const trimmedInput: string = input.trim();

    if (trimmedInput.toLowerCase() != trimmedInput) {
        //Contains upper case
        return trimmedInput;
    } else if (trimmedInput.length == 2) {
        return regexProvinceWith2Letters.test(trimmedInput) ? input : input.toUpperCase();
    } else {
        return AutoCap(input);
    }
};

export const GetTextColourFromBackground = (backgroundColour: string): string => {
    // Text colour is based on background colour so the text will show up
    const white = "#fff";
    const black = "#000";

    if (backgroundColour === null) {
        return white;
    }
    const hex: string = backgroundColour.replace(/#/, "");
    const r: number = parseInt(hex.substr(0, 2), 16);
    const g: number = parseInt(hex.substr(2, 2), 16);
    const b: number = parseInt(hex.substr(4, 2), 16);

    const lumina: number = [0.299 * r, 0.587 * g, 0.114 * b].reduce((x, y) => x + y);

    return lumina > 162 ? black : white;
};

export const GetOrdinalForDayOfTheMonth = (day: number): string => {
    if (day) {
        if (day > 3 && day < 21) {
            return "th";
        }
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    } else {
        return "";
    }
};
