<template>
    <cb-page-layout
        :footerColor="VisualSettings?.FooterColor"
        :backgroundImage="VisualSettings?.BackgroundImageURL"
        :backgroundType="VisualSettings?.BackgroundType"
        :backgroundColor="VisualSettings?.BackgroundColor"
    >
        <template #body>
            <cb-header
                v-if="VisualSettings?.HeaderImageUrl"
                :headerImageUrl="VisualSettings?.HeaderImageUrl"
                :headerImageAlignment="VisualSettings?.HeaderImageAlignment"
            />
            <router-view v-if="!IsLoading" />
        </template>
        <template #footer>
            <div v-html="VisualSettings?.Footer"></div>
        </template>
    </cb-page-layout>
</template>

<script setup lang="ts">
import { GetTextColourFromBackground } from "./utils/StringFunctions";
import { onBeforeMount } from "vue";
import router from "@/router";

import { applyOptions } from "@campbrain/cortex";
import { useSiteSettings } from "./stores/SiteSettingsStore";
import { storeToRefs } from "pinia";

const siteSettingsStore = useSiteSettings();
const { VisualSettings, SiteSettings, ButtonTextColour, IsValidSite, IsEnabled, IsLoading } = storeToRefs(siteSettingsStore);

async function GetVisualSettings() {
    IsLoading.value = true;
    await siteSettingsStore.getSiteInfo();

    if (!IsEnabled.value) {
        router.push({
            name: "SiteNotFound",
        });
        return;
    }

    if (!IsValidSite.value) {
        router.push({
            name: "SiteNotEnabled",
        });
        return;
    }

    ButtonTextColour.value = GetTextColourFromBackground(VisualSettings.value.DarkColor);

    document.title = VisualSettings.value.SiteName;

    if (SiteSettings.value.FacebookPixelEnabled) {
        (<any>window).fbq("init", SiteSettings.value.FacebookPixelId);
        (<any>window).fbq("track", "PageView");
    }

    applyOptions({
        colours: {
            primary: VisualSettings.value.BaseColor,
            accent: VisualSettings.value.DarkColor,
        },
    });

    router.push({
        name: "Form",
    });
}
onBeforeMount(async () => {
    await GetVisualSettings();
    IsLoading.value = false;
});
</script>
<style>
#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

img {
    max-width: 100%;
    height: auto !important;
    /* override froala's inline height */
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>

<style>
hr {
    border-color: rgba(0, 0, 0, 0.12);
    margin-bottom: 24px;
}

label.text-h6 {
    font-weight: 600;
}

footer p {
    margin-bottom: 0px;
}

body {
    font-size: 16px;
}
.separator {
    border: 1px solid #f2f2f2;
    margin: 16px 0px;
}
.section-title {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}
.padding-bottom-16 {
    padding-bottom: 16px;
}

.section-subtitle {
    font-weight: 600;
    font-size: 15px;
    color: #4f4f4f;
}

main {
    max-width: 640px;
}

.q-btn {
    text-transform: none !important;
}

/* Overrule Quasar's border on hover */
.q-field__control:hover:before {
    border-color: rgba(0, 0, 0, 0.24) !important;
}
/* Overrule Quasar's dropdown animation */
.rotate-180 {
    transform: unset !important;
}
/* Overrule Quasar's dropdown size */
.q-select__dropdown-icon {
    font-size: 12px;
}
/* Overrule Quasar's checkbox size */
.q-checkbox__inner {
    font-size: 36px;
}
/* Overrule Quasar's checkbox animation on hover */
.q-checkbox__inner:before {
    background: transparent !important;
}
/* Overrule Quasar's padding for validation */
.q-field__bottom {
    padding: 4px 12px 0;
}
/* Overrule Quasar's error icon in dropdowns */
.fa-exclamation-circle:before {
    content: unset;
}
</style>
