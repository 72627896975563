import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "recurring-text" }

import DayOfTheMonthMenuItem from "@/models/DayOfTheMonthMenuItem";
import { useDonationStore } from "@/stores/DonationStore";
import { useSiteSettings } from "@/stores/SiteSettingsStore";
import { GetOrdinalForDayOfTheMonth } from "@/utils/StringFunctions";
import useVuelidate from "@vuelidate/core";
import { required, minValue, helpers, maxLength, requiredIf } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DonationInformation',
  setup(__props) {

const donationStore = useDonationStore();
const { DonationAmount, DonationNote, IsRecurringDonation, DayOfRecurrence } = storeToRefs(donationStore);

const siteSettingsStore = useSiteSettings();
const { SiteSettings, IsRecurringDonationsFeatureEnabled } = storeToRefs(siteSettingsStore);

const validations = computed(() => ({
    DonationAmount: { required, minValue: minValue(SiteSettings.value.MinimumAmount) },
    DonationNote: {
        maxLength: helpers.withMessage(
            ({ $model }) => `This field has a character limit of 4000. You entered ${$model.length}.`,
            maxLength(4000),
        ),
    },
    DayOfRecurrence: {
        required: requiredIf(() => {
            return IsRecurringDonation.value;
        }),
    },
}));

const vuelidate = useVuelidate(validations, { DonationAmount, DonationNote, DayOfRecurrence }) as any;

const currentDate = new Date();
const DaysOfTheMonth: number[] = Array.from(
    { length: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate() },
    (_, i) => i + 1,
);

const DaysOfTheMonthMenuItems: DayOfTheMonthMenuItem[] = DaysOfTheMonth.map((dayOfTheMonth: number) => {
    return new DayOfTheMonthMenuItem(`${dayOfTheMonth}${GetOrdinalForDayOfTheMonth(dayOfTheMonth)}`, dayOfTheMonth);
});

const dayOfTheMonth: DayOfTheMonthMenuItem = DaysOfTheMonthMenuItems.find((day: DayOfTheMonthMenuItem) => {
    return day.Value === DayOfRecurrence.value;
});

const DayOfRecurrenceValue = ref<DayOfTheMonthMenuItem>(dayOfTheMonth);

function dayOfRecurrenceUpdated(newValue: DayOfTheMonthMenuItem): void {
    if (newValue) {
        DayOfRecurrence.value = newValue.Value;
    } else {
        DayOfRecurrence.value = null;
    }
}

return (_ctx: any,_cache: any) => {
  const _component_cb_button = _resolveComponent("cb-button")!
  const _component_cb_col = _resolveComponent("cb-col")!
  const _component_cb_row = _resolveComponent("cb-row")!
  const _component_cb_money_input = _resolveComponent("cb-money-input")!
  const _component_cb_textarea = _resolveComponent("cb-textarea")!
  const _component_cb_select = _resolveComponent("cb-select")!
  const _component_cb_card = _resolveComponent("cb-card")!

  return (_openBlock(), _createBlock(_component_cb_card, {
    rounded: true,
    "big-rounded": true,
    class: "amount-container"
  }, {
    default: _withCtx(() => [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "section-title padding-bottom-16" }, "Enter an Amount", -1)),
      (_unref(IsRecurringDonationsFeatureEnabled))
        ? (_openBlock(), _createBlock(_component_cb_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_col, { class: "buttons-container" }, {
                default: _withCtx(() => [
                  _createVNode(_component_cb_button, {
                    label: "One-Time Donation",
                    color: "accent",
                    inverted: _unref(IsRecurringDonation),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (IsRecurringDonation.value = false))
                  }, null, 8, ["inverted"]),
                  _createVNode(_component_cb_button, {
                    label: "Monthly Donation",
                    color: "accent",
                    inverted: !_unref(IsRecurringDonation),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (IsRecurringDonation.value = true))
                  }, null, 8, ["inverted"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_cb_row, { class: "donation-amount-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_cb_col, {
            desktop: "4",
            tablet: "4",
            phone: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cb_money_input, {
                modelValue: _unref(DonationAmount),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(DonationAmount) ? (DonationAmount).value = $event : null)),
                label: "Amount",
                name: "amount",
                validate: _unref(vuelidate).DonationAmount,
                dense: "",
                "money-whole": "",
                big: ""
              }, null, 8, ["modelValue", "validate"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_cb_row, { class: "donation-note-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_cb_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_cb_textarea, {
                modelValue: _unref(DonationNote),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(DonationNote) ? (DonationNote).value = $event : null)),
                label: "Donation note (optional)",
                name: "note",
                validate: _unref(vuelidate).DonationNote,
                error: _unref(vuelidate).DonationNote.$error,
                errorMessage: _unref(vuelidate).DonationNote.$errors[0]?.$message,
                dense: "",
                textareaAutogrow: ""
              }, null, 8, ["modelValue", "validate", "error", "errorMessage"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_unref(IsRecurringDonationsFeatureEnabled) && _unref(IsRecurringDonation))
        ? (_openBlock(), _createBlock(_component_cb_row, {
            key: 1,
            class: "donation-recurrence-day"
          }, {
            default: _withCtx(() => [
              _cache[7] || (_cache[7] = _createElementVNode("div", {
                style: {"width":"100%"},
                class: "separator"
              }, null, -1)),
              _createVNode(_component_cb_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", null, "This donation will repeat on the  ", -1)),
                    _createVNode(_component_cb_select, {
                      modelValue: DayOfRecurrenceValue.value,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((DayOfRecurrenceValue).value = $event)),
                      options: _unref(DaysOfTheMonthMenuItems),
                      optionLabel: "Name",
                      error: _unref(vuelidate).DayOfRecurrence.$error,
                      errorMessage: "Value is required",
                      class: "validation-adjust",
                      onChange: dayOfRecurrenceUpdated
                    }, null, 8, ["modelValue", "options", "error"]),
                    _cache[6] || (_cache[6] = _createElementVNode("label", null, "  of the month.", -1))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})