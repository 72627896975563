import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "caption" }
const _hoisted_6 = { style: {"white-space":"nowrap"} }

import * as Gateway from "@campbrain/bigbrain.givingportal.gateway";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ref, computed, onBeforeMount } from "vue";
import router from "@/router";

import { CBPayField, Notify } from "@campbrain/cortex";
import { useSiteSettings } from "@/stores/SiteSettingsStore";
import { storeToRefs } from "pinia";
import { useDonationStore } from "@/stores/DonationStore";

import DonorInformation from "@/components/DonorInformation.vue";
import DonationInformation from "@/components/DonationInformation.vue";
import DirectYourDonation from "@/components/DirectYourDonation.vue";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";


export default /*@__PURE__*/_defineComponent({
  __name: 'DonationForm',
  setup(__props) {

const invisibleHcaptcha = ref<VueHcaptcha | null>(null);

const siteSettingsStore = useSiteSettings();
const { Version, Description, ButtonTextColour, IsEnabled } = storeToRefs(siteSettingsStore);

const donationStore = useDonationStore();
const {
    DonationAttempts,
    DonationAmount,
    Email,
    SubmitPaymentClicked,
    IsLoading,
    GivingOptions,
    CardholderName,
    Token,
    SelectedGivingOption,
    IsSubmittingPayment,
    ShowBamboraError,
    ByPassCaptcha,
    RecaptchaToken,
    PaymentDeclined,
    PreviousSubmissionDeclinedDonationId,
    ShowConfirmation,
    paymentMethod,
} = storeToRefs(donationStore);

const PayField = ref<typeof CBPayField>();
const ErrorMessage = ref<string>("");
const publicToken = ref("");
const odinResults = ref();
const waitingOdinResponse = ref(false);
const currentRecaptchaKey = ref("");
const errorKey = ref("");
const formDisabled = ref(false);

const isOdin = computed(() => {
    return paymentMethod.value.Processor?.Processor === Gateway.ProcessorType.Odin;
});

const currentProcessor = computed(() => {
    if (isOdin.value) getPublicToken();
    return paymentMethod.value.Processor?.Processor;
});
const creditCardComponentReady = computed(() => {
    return (
        currentProcessor.value === Gateway.ProcessorType.Bambora ||
        (currentProcessor.value === Gateway.ProcessorType.Odin && publicToken.value != "")
    );
});

function DispatchValidation(): void {
    SubmitPaymentClicked.value = !SubmitPaymentClicked.value;
}

const SiteHasPaymentOption = computed<boolean>(() => {
    return !!siteSettingsStore.SiteSettings && !!siteSettingsStore.SiteSettings.SelectedPaymentOptionId;
});
function OnCaptchaExpired() {
    if (!ByPassCaptcha.value && invisibleHcaptcha.value) {
        invisibleHcaptcha.value.reset();
    }
}

function CheckForBypassParam(): boolean {
    const queryString: string = window.location.search.substring(1);

    const params: { [id: string]: string } = {};

    const queries = queryString.split("&");

    queries.forEach((indexQuery: string) => {
        const indexPair = indexQuery.split("=");

        const queryKey: string = decodeURIComponent(indexPair[0]);
        const queryValue: string = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : "");

        params[queryKey] = queryValue;
    });

    return params["specflowletsgo"] === "true";
}

function OnCaptchaVerified(token: string, eKey: string): void {
    errorKey.value = eKey;
    ValidateOnSubmit(token);
}

function FireCaptcha(): void {
    if (invisibleHcaptcha.value) {
        invisibleHcaptcha.value.execute();
    }
}

function closeDialog(paymentDeclined: boolean): void {
    ShowConfirmation.value = false;
    ShowBamboraError.value = paymentDeclined;
    PayField.value.refreshToken();
    OnCaptchaExpired();
}

function checkOdinResult(odinResponse) {
    odinResults.value = odinResponse;
    waitingOdinResponse.value = false;
    executeValidation(currentRecaptchaKey.value);
    if (!odinResponse.success) {
        document.getElementById("parentDivodin-card-info").style.borderColor = "red";
        document.getElementById("parentDivodin-card-info").style.borderWidth = "2px";
    }
}

function OnSubmitted(): void {
    if (Token.value == null) {
        DispatchValidation();
    } else {
        DonationAttempts.value = DonationAttempts.value + 1;
        IsSubmittingPayment.value = true;
        PaymentDeclined.value = false;
        ShowBamboraError.value = false;
        Gateway.ApiGateway.SubmitDonation(donationStore.ToDTO()).then((response) => {
            if (response.data.Success) {
                if (siteSettingsStore.SiteSettings.FacebookPixelEnabled) {
                    (<any>window).fbq("track", "Donate", {
                        value: DonationAmount,
                        currency: siteSettingsStore.SiteSettings.FacebookPixelCurrency,
                    });
                }
                router.push({
                    name: "Confirmation",
                });
            } else {
                PaymentDeclined.value = true;
                OnCaptchaExpired();
                PreviousSubmissionDeclinedDonationId.value = response.data.Id;
                ErrorMessage.value = "The transaction could not be completed. Reason: " + response.data.Exception;
                PayField.value.refreshToken();
            }
            IsSubmittingPayment.value = false;
        });
    }
}

function executeValidation(recaptchaToken: string = "") {
    if (validateForm() && ByPassCaptcha.value) {
        PayField.value.refreshToken();
        PaymentDeclined.value = false;
        ShowConfirmation.value = true;
    } else if (validateForm() && !ByPassCaptcha.value) {
        PayField.value.refreshToken();
        PaymentDeclined.value = false;
        RecaptchaToken.value = recaptchaToken;
        ShowConfirmation.value = true;
    } else {
        if (!ByPassCaptcha.value) {
            OnCaptchaExpired();
        }
        setTimeout(() => {
            ScrollToError();
        }, 100);
    }
}

function ValidateOnSubmit(recaptchaToken: string): void {
    currentRecaptchaKey.value = recaptchaToken;

    if (DonationAttempts.value >= process.env.VUE_APP_MAX_DONATION_ATTEMPTS) {
        formDisabled.value = true;
        Notify.create({
            //Generic System Error
            message:
                "We were unable to process your donation and you have exceeded the number of retries available. Refresh the page to try again.",
            type: "cb-error",
            icon: "",
            group: false,
            position: "center",
            multiLine: true,
            timeout: 0,
            html: true,
            actions: [
                {
                    label: "Refresh",
                    color: "red-10",
                    handler: () => {
                        window.location.reload();
                    },
                },
            ],
        });

        return;
    }

    DispatchValidation();
    if (currentProcessor.value !== Gateway.ProcessorType.Odin) {
        executeValidation(recaptchaToken);
        return;
    }

    waitingOdinResponse.value = true;
    odinResults.value = null;
    document.getElementById("odin-submit")?.click();
}

const validations = computed(() => ({
    CardholderName: { required },
    Token: { required },
}));

const vuelidate = useVuelidate(validations, {
    CardholderName,
    Token,
});

function validateForm(): boolean {
    vuelidate.value.$validate();
    if (vuelidate.value.$errors.length > 0) {
        return false;
    }
    return true;
}

function ScrollToError() {
    const el = document.querySelector(".text-negative:first-of-type");
    if (el) el.scrollIntoView();
}

ByPassCaptcha.value = CheckForBypassParam();

async function getPublicToken() {
    const result = await Gateway.ApiGateway.PaymentPublicToken(paymentMethod.value.Id);
    publicToken.value = result.data.Token;
}

onBeforeMount(async () => {
    IsLoading.value = true;
    if (IsEnabled.value) {
        await donationStore.GetGivingOptions();
        await donationStore.GetDonationAttributingSpelling();
        await donationStore.getPaymentMethods();
    }

    if (GivingOptions.value.length === 0 || !SiteHasPaymentOption.value) {
        router.push({
            name: "SiteNotEnabled",
        });
    }

    IsLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_cb_spinner = _resolveComponent("cb-spinner")!
  const _component_cb_alert = _resolveComponent("cb-alert")!
  const _component_cb_col = _resolveComponent("cb-col")!
  const _component_cb_row = _resolveComponent("cb-row")!
  const _component_cb_pay_field = _resolveComponent("cb-pay-field")!
  const _component_cb_card = _resolveComponent("cb-card")!
  const _component_cb_button = _resolveComponent("cb-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(IsLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_cb_spinner)
        ]))
      : _createCommentVNode("", true),
    (!_unref(IsLoading) && _unref(GivingOptions) && _unref(GivingOptions).length > 0 && SiteHasPaymentOption.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("form", {
            ref: "form",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", {
              class: "main-description",
              innerHTML: _unref(Description)
            }, null, 8, _hoisted_3),
            _createVNode(DonationInformation),
            _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
            _createVNode(DirectYourDonation),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
            _createVNode(DonorInformation),
            _createElementVNode("div", null, [
              _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_cb_card, {
                rounded: "",
                "big-rounded": "",
                class: "payment-container"
              }, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "section-title padding-bottom-16" }, "Payment Information", -1)),
                  _createVNode(_component_cb_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_cb_col, null, {
                        default: _withCtx(() => [
                          (_unref(ShowBamboraError))
                            ? (_openBlock(), _createBlock(_component_cb_alert, {
                                key: 0,
                                icon: "fas fa-exclamation-triangle",
                                color: "orange",
                                dismissible: "",
                                onDismiss: _cache[0] || (_cache[0] = ($event: any) => (ShowBamboraError.value = false))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(ErrorMessage.value), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (creditCardComponentReady.value)
                    ? (_openBlock(), _createBlock(_component_cb_row, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_cb_col, {
                            class: _normalizeClass(currentProcessor.value === Gateway.ProcessorType.Odin ? 'odin' : '')
                          }, {
                            default: _withCtx(() => [
                              (!formDisabled.value)
                                ? (_openBlock(), _createBlock(_component_cb_pay_field, {
                                    key: 0,
                                    ref_key: "PayField",
                                    ref: PayField,
                                    tokenValue: _unref(Token),
                                    "onUpdate:tokenValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(Token) ? (Token).value = $event : null)),
                                    cardholderName: _unref(CardholderName),
                                    "onUpdate:cardholderName": _cache[2] || (_cache[2] = ($event: any) => (_isRef(CardholderName) ? (CardholderName).value = $event : null)),
                                    errorCardholder: _unref(vuelidate).CardholderName.$error,
                                    errorToken: _unref(vuelidate).Token.$error,
                                    twoRows: "",
                                    publicToken: publicToken.value,
                                    processor: currentProcessor.value,
                                    onOdinResult: checkOdinResult
                                  }, null, 8, ["tokenValue", "cardholderName", "errorCardholder", "errorToken", "publicToken", "processor"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["class"])
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_cb_spinner, { key: 1 }))
                ]),
                _: 1
              }),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
              (_unref(ByPassCaptcha))
                ? (_openBlock(), _createBlock(_component_cb_button, {
                    key: 0,
                    color: "accent",
                    label: "Review and Submit",
                    disabled: formDisabled.value,
                    loading: isOdin.value && waitingOdinResponse.value,
                    type: "submit",
                    onClick: ValidateOnSubmit
                  }, null, 8, ["disabled", "loading"]))
                : (_openBlock(), _createBlock(_component_cb_button, {
                    key: 1,
                    id: "CaptchaSubmit",
                    loading: isOdin.value && waitingOdinResponse.value,
                    color: "accent",
                    label: "Review and Submit",
                    disabled: formDisabled.value,
                    type: "submit",
                    onClick: FireCaptcha
                  }, null, 8, ["loading", "disabled"])),
              _createVNode(ConfirmationDialog, {
                ButtonTextColour: _unref(ButtonTextColour),
                DonationAmount: _unref(DonationAmount),
                Email: _unref(Email),
                GivingOption: _unref(SelectedGivingOption) && _unref(SelectedGivingOption).Name,
                IsSubmittingPayment: _unref(IsSubmittingPayment),
                PaymentDeclined: _unref(PaymentDeclined),
                onDonationSubmitted: OnSubmitted,
                onCloseDialog: closeDialog
              }, null, 8, ["ButtonTextColour", "DonationAmount", "Email", "GivingOption", "IsSubmittingPayment", "PaymentDeclined"]),
              (!_unref(ByPassCaptcha))
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_unref(VueHcaptcha), {
                      ref_key: "invisibleHcaptcha",
                      ref: invisibleHcaptcha,
                      sitekey: _unref(siteSettingsStore).CaptchaSiteKey,
                      size: "invisible",
                      onVerify: OnCaptchaVerified,
                      onExpired: OnCaptchaExpired,
                      onChallengeExpired: OnCaptchaExpired
                    }, null, 8, ["sitekey"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 544),
          _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"caption\" data-v-0b9d60db><p style=\"margin:16px 0px 0px;\" data-v-0b9d60db> By submitting your donation, you agree to our <span style=\"white-space:nowrap;\" data-v-0b9d60db><a href=\"https://www.campbrain.com/olrterms\" target=\"_blank\" class=\"text-accent\" data-v-0b9d60db>Terms</a> and <a href=\"https://www.campbrain.com/olrprivacy\" target=\"_blank\" class=\"text-accent\" style=\"white-space:nowrap;\" data-v-0b9d60db>Privacy Policy</a>. </span></p><p data-v-0b9d60db> This site is protected by <a href=\"https://www.hCaptcha.com\" target=\"_blank\" data-v-0b9d60db>hCaptcha</a> and its <a href=\"https://www.hcaptcha.com/privacy\" target=\"_blank\" data-v-0b9d60db>Privacy Policy</a> and <a href=\"https://www.hcaptcha.com/terms\" target=\"_blank\" data-v-0b9d60db>Terms of Service</a> apply. </p></div>", 1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, " © " + _toDisplayString(new Date().getFullYear()) + " BrainRunner Inc. | " + _toDisplayString(_unref(Version)), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})